import React, { useEffect, useCallback, useState } from "react";
import Card from "../UI/Card";
import styles from "./Referral.module.css";
// import {
//   TableContainer,
//   Table,
//   Paper,
//   TableHead,
//   TableRow,
//   TableBody,
//   TableCell,
//   TablePagination,
// } from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import HeatMap from "./Charts/HeatMap/HeatMap.jsx";
import Filter from "./Charts/Map/Filter";
import { Button } from "@mui/material";

import Map from "./Charts/Map/Map";

import { useAuth0 } from '@auth0/auth0-react';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const download = function (data, name) {
  const blob = new Blob([data], { type: "text/csv" });

  const url = window.URL.createObjectURL(blob);

  const a = document.createElement("a");

  a.setAttribute("href", url);

  a.setAttribute("download", name + ".csv");

  a.click();
};

const csvmaker = function (data) {
  let csvRows = [];

  const headers = Object.keys(data[0]);

  csvRows.push(headers.join(","));

  for (let i = 0; i < data.length; i++) {
    const values = Object.values(data[i]).join(",");
    csvRows.push(values);
  }

  return csvRows.join("\n");
};

const schemeColors = [
  "#9E0142",
  "#66C2A5",
  "#3288BD",
  "#F46D43",
  "#CAC111",
  "#5E4FA2",
  "#FDAE61",
  "#CAF510",
  "#FEE08B",
  "#D53E4F",
  "#FFFF30",
];

const Refferal = (props) => {
  const [colorScheme, setColorScheme] = useState({});

  const [mapData, setMapData] = useState({
    geoJson: {
      type: "features",
      features: [],
      devices: [],
    },
    center: [0, 0],
  });

  const [tableData, setTableData] = useState({ columns: [], data: [] });
  const [distanceAnalysis, setdistanceAnalysis] = useState({
    columns: [],
    data: [],
  });
  const [pieData, setPieData] = useState([]);

  const [filterData, setFilterData] = useState({
    origin: "",
    testType: "",
    origin_province: "",
    origin_district: "",
    referralType: "HF_LAB",
    radius: 0,
    rank: 1,
    changeScheme: true,
  });

  const { fileName, setError } = props;

  const { getAccessTokenSilently } = useAuth0();

  const getData = useCallback(async () => {
    try {
      if (fileName === "") {
        const message = ["No file selected"];
        throw message;
      }
      const token = await getAccessTokenSilently();
      const authHeaders = {
        Authorization: `Bearer ${token}`
      }

      const url2 = `${process.env.REACT_APP_API_BASE_URL}/v1/userDefinedRadius?fileName=${fileName}&radius=${filterData.radius}`;
      const formData = new FormData();
      formData.append("radius", filterData.radius);
      const response2 = await fetch(url2, {
        method: "POST",
        body: JSON.stringify({
          radius: filterData.radius,
        }),
        headers: authHeaders
      });
      const data2 = await response2.json();
      if (!response2.ok) {
        throw data2.errors;
      }

      const url = `${process.env.REACT_APP_API_BASE_URL}/v1/refferalFilterValues?fileName=${fileName}&Health_Facility=${filterData.origin}&TestType=${filterData.testType}&Province=${filterData.origin_province}&District=${filterData.origin_district}&referralType=${filterData.referralType}&rank=${filterData.rank}`;

      const response = await fetch(url, {headers: authHeaders});
      const data = await response.json();
      if (!response.ok) {
        throw data.errors;
      }
      setTableData(data.table);
      setdistanceAnalysis(() => {
        const columns = data.table2.columns.map((c) => {
          if (c === "Demand satisfied") {
            return {
              field: c,
              headerName: c,
              width: 150,
              editable: true,
              renderCell: (p) => {
                return p.row["Demand satisfied"] === "1" ? (
                  <span
                    style={{ color: "green" }}
                    className="material-icons-sharp"
                  >
                    check_circle
                  </span>
                ) : (
                  <span
                    style={{ color: "lightgray" }}
                    className="material-icons-sharp"
                  >
                    cancel
                  </span>
                );
              },
            };
          } else
            return {
              field: c,
              headerName: c,
              width: 150,
              editable: true,
            };
        });
        columns.unshift({ field: "id", headerName: "ID", width: 40 });

        const modifiedData = data.table2.data.map((d, ind) => {
          return {
            id: ind + 1,
            ...d,
          };
        });

        return {
          columns: columns,
          data: modifiedData,
        };
      });
      setPieData(data.pie);
      setTimeout(() => {
        setMapData((pv) => {
          return {
            geoJson: data.map,
            center:
              data.map.features.length === 0
                ? pv.center
                : [...data.map.features[0].geometry.coordinates[0][0]],
          };
        });
        if (filterData.changeScheme) {
          setColorScheme(
            data.map.devices.reduce((acc, d, ind) => {
              acc[d] = schemeColors[ind];
              return acc;
            }, {})
          );
        }
      }, 100);
    } catch (error) {
      setError({ status: true, message: error });
    }
  }, [filterData, fileName, setError]);

  useEffect(() => {
    getData();
  }, [getData]);

  const filterChangeHandler = (newFilter) => {
    setFilterData((pv) => ({ ...pv, ...newFilter, changeScheme: false }));
  };

  const changeRadius = useCallback((r) => {
    setFilterData((pv) => ({ ...pv, radius: r }));
  }, []);

  const get = (data, name) => {
    const csvdata = csvmaker(data);
    download(csvdata, name);
  };

  return (
    <>
      <div className={styles["referral-container"]}>
        <Map
          radius={filterData.radius}
          referralType={filterData.referralType}
          center={[...mapData.center]}
          mapData={mapData.geoJson}
          colorScheme={colorScheme}
        />
        <Card className={styles.filter}>
          <Filter fileName={fileName} changeFilter={filterChangeHandler} />
        </Card>
      </div>
      <div className={styles["table-group"]}>
        <HeatMap
          get={get.bind(null, pieData, "DistanceDemand(HeatMapData)")}
          id={"distanceDemand"}
          data={pieData}
          changeRadius={changeRadius}
          heading={`Unmet demand within ${filterData.radius} Km`}
          colorScheme={colorScheme}
        />

        <Card className={styles["data-grid-container"]}>
          <div className={styles["table-heading"]}>
            <h1>Demand satisfied within {filterData.radius} Km</h1>
            <Button onClick={get.bind(null, tableData.data, "MergeDistance")}>
              Export Potential Transportation Lane
            </Button>
          </div>
          <Box
            sx={{ marginTop: "10px", height: "90%", width: "100%" }}
            // component={Paper}
          >
            <DataGrid
              components={{ Toolbar: CustomToolbar }}
              rows={distanceAnalysis.data}
              columns={distanceAnalysis.columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25]}
              disableRowSelectionOnClick
            />
          </Box>
        </Card>
      </div>
    </>
  );
};

export default React.memo(Refferal);
