import L from "leaflet";
import {
  Polyline,
  TileLayer,
  Marker,
  Tooltip,
  useMap,
  LayersControl,
  LayerGroup,
  Circle,
} from "react-leaflet";
import { useCallback, useEffect } from "react";
import styles from "./MapContent.module.css";
import { useState } from "react";

const LabIcon = new L.Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/128/4274/4274096.png",
  iconSize: [20, 20],
});
const HFIcon = new L.Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/128/6038/6038994.png",
  iconSize: [20, 20],
});
const HubIcon = new L.Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/432/432766.png",
  iconSize: [18, 18],
});

const legend = L.control({ position: "bottomleft" });
const recenterMap = (map, center) => {
  map.panTo(new L.latLng([...center]));
};

const MapContent = (props) => {
  const map = useMap();
  const { center, colorScheme } = props;
  const [minimize, setMinimize] = useState(true);
  const mapDevices = props.mapData.devices;

  const changeMinimize = useCallback(() => {
    setMinimize((pv) => !pv);
  }, []);

  useEffect(() => {
    legend.onAdd = () => {
      const div = L.DomUtil.create(
        "div",
        `${minimize ? styles.collapsed : `${styles.info} ${styles.legend}`}`
      );
      div.onclick = changeMinimize;
      if (!minimize) {
        const devices = mapDevices;

        let labels = [];

        devices.forEach((device) => {
          labels.push(
            `<div class=${styles.spacing}><i style="background:` +
              colorScheme[device] +
              '"></i> ' +
              device +
              "</div>"
          );
        });
        div.innerHTML = labels.join("");
      } else {
        div.innerHTML = `<span class="material-icons-sharp">legend_toggle</span>`;
      }
      return div;
    };
    recenterMap(map, center);
    legend.addTo(map);
  }, [center, map, mapDevices, colorScheme, minimize, changeMinimize]);

  return (
    <>
      <LayersControl position="topright">
        <LayersControl.BaseLayer
          children={<input type={"checkbox"} />}
          name={`Basic view`}
        >
          <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer checked name="High contrast - dark">
          <TileLayer url="https://{s}.tile.jawg.io/jawg-dark/{z}/{x}/{y}{r}.png?access-token=zR3ZLKZ094PCqnfe9kuDQzMus1bZXR1CrQMYWqjrpUmv8xrBszAhqOIhCQ1Hj2a5" />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="High contrast - light">
          <TileLayer url="https://{s}.tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token=zR3ZLKZ094PCqnfe9kuDQzMus1bZXR1CrQMYWqjrpUmv8xrBszAhqOIhCQ1Hj2a5" />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay checked name="Origin">
          <LayerGroup>
            {props.mapData.features.map((feature) => {
              return (
                <>
                  <Marker
                    position={feature.geometry.coordinates[0][0]}
                    icon={
                      props.referralType === "HF_LAB" ||
                      props.referralType === "HF_Hub"
                        ? HFIcon
                        : HubIcon
                    }
                  >
                    <Tooltip>{"Origin: " + feature.properties.Origin}</Tooltip>
                  </Marker>
                </>
              );
            })}
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name={`Destination`}>
          <LayerGroup>
            {props.mapData.features.map((feature) => {
              return (
                <>
                  {feature.geometry.coordinates.map((latlong, ind) => {
                    return (
                      <Marker
                        key={ind}
                        position={latlong[1]}
                        icon={
                          props.referralType === "HF_LAB" ||
                          props.referralType === "HUB_LAB"
                            ? LabIcon
                            : HubIcon
                        }
                      >
                        <Tooltip>
                          {"Destination: " +
                            feature.properties.labData[ind].name}
                        </Tooltip>
                      </Marker>
                    );
                  })}
                </>
              );
            })}
          </LayerGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay checked name="Connections">
          <LayerGroup>
            {props.mapData.features.map((feature) => {
              return (
                <>
                  {feature.geometry.coordinates.map((coordinates, ind) => {
                    return (
                      <Polyline
                        pathOptions={{
                          color:
                            colorScheme[feature.properties.labData[ind].Test],
                        }}
                        positions={coordinates}
                      >
                        <Tooltip>
                          {"Distance : " +
                            feature.properties.labData[ind].Distance +
                            " Kms"}
                          <br />
                          {"Product : " + feature.properties.labData[ind].Test}
                          <br />
                          {"Annual samples referred : " +
                            feature.properties.labData[ind]
                              .AnnualSamplesReferred}
                        </Tooltip>
                      </Polyline>
                    );
                  })}
                </>
              );
            })}
          </LayerGroup>
        </LayersControl.Overlay>
      </LayersControl>
    </>
  );
};

export default MapContent;
