import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, message: [] };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, message: [error.message] };
  }
  render() {
    if (this.state.hasError) {
      this.props.setError(this.state);
      this.props.setPage(0);
      this.setState({ hasError: false, message: [] });
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
