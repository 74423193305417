import React, { useState, useCallback, useEffect } from "react";
import styles from "./HistoricalTesting.module.css";
import BarChart from "./Charts/Bar/Bar";
import Filter from "./Filter/Filter";
import Map from "./Charts/Map/Map";
import Loader from "../UI/Loader";
import PieChart from "./Charts/Pie/PieChart";

import { useAuth0 } from "@auth0/auth0-react";

const download = function (data, name) {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", name + ".csv");
  a.click();
};

const csvmaker = function (data) {
  let csvRows = [];
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(","));
  for (let i = 0; i < data.length; i++) {
    const values = Object.values(data[i]).join(",");
    csvRows.push(values);
  }
  return csvRows.join("\n");
};

const schemeColors = [
  "#9E0142",
  "#66C2A5",
  "#3288BD",
  "#F46D43",
  "#CAC111",
  "#5E4FA2",
  "#FDAE61",
  "#CAF510",
  "#FEE08B",
  "#D53E4F",
  "#FFFF30",
];

const HistoricalTesting = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState({
    province: "",
    deviceType: "",
    changeScheme: true,
  });
  const [provinceUtilBar, setProvinceUtilBar] = useState([]);
  const [DeviceUtilBar, setDeviceUtilBar] = useState([]);
  const [mapData, setMapData] = useState({ mapData: [], center: [0, 0] });
  const [pie, setPie] = useState([]);
  const [colorScheme, setColorScheme] = useState({});

  const { getAccessTokenSilently } = useAuth0();

  const { fileName, setError, setPage } = props;

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      if (fileName === "") {
        const message = ["No file selected"];
        throw message;
      }

      const token = await getAccessTokenSilently();

      const url = `${process.env.REACT_APP_API_BASE_URL}/v1/data/${fileName}`;
      const authHeaders = {
        Authorization: `Bearer ${token}`,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          province: filter.province === "" ? undefined : filter.province,
          deviceType: filter.deviceType === "" ? undefined : filter.deviceType,
        }),
      });
      const data = await response.json();
      if (!response.ok) throw data.errors;
      if (!data.map.center[0] || !data.map.center[1]) {
        setMapData((pv) => {
          return { mapData: data.map.mapData, center: pv.center };
        });
      } else setMapData({ mapData: data.map.mapData, center: data.map.center });
      setProvinceUtilBar(data.provinceCapcityUtil);
      setDeviceUtilBar(data.deviceCapacityUtil);
      setPie(data.pie);
      setIsLoading(false);
      if (filter.changeScheme) {
        const devices = data.deviceCapacityUtil.map((d) => d.DeviceType);
        setColorScheme(
          devices.reduce((acc, d, ind) => {
            acc[d] = schemeColors[ind];
            return acc;
          }, {})
        );
      }
    } catch (error) {
      setError({ status: true, message: error });
    }
  }, [filter, fileName, setError, setIsLoading]);

  useEffect(() => {
    getData();
  }, [getData, filter]);

  const filterChangeHandler = useCallback(
    (updatedValues) => {
      setFilter({ ...updatedValues, changeScheme: false });
    },
    [setFilter]
  );

  const throwError = (error) => {
    setError({ status: true, message: error.message });
    setPage(0);
  };

  const get = useCallback((data, name) => {
    const csvdata = csvmaker(data);
    download(csvdata, name);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <>
          <Filter
            setError={setError}
            province={filter.province}
            deviceType={filter.deviceType}
            fileName={fileName}
            changeFilter={filterChangeHandler}
          />
          <div className={styles.mapAndPie}>
            <Map
              setError={throwError}
              mapData={[...mapData.mapData]}
              center={[...mapData.center]}
            />
            <PieChart
              get={get.bind(null, pie, "HistoricalTestingByDevice(PieChart)")}
              heading="Historical Production by Equipment Type"
              data={pie}
              id={"Historical testing by device"}
              colorScheme={colorScheme}
            />
          </div>
          <div className={styles.barContainer}>
            <BarChart
              colorScheme={colorScheme}
              get={get.bind(
                null,
                provinceUtilBar,
                "HistoricalTestingByProvince(BarChart)"
              )}
              heading="Historical Production by province"
              data={provinceUtilBar}
              keys={Object.keys(provinceUtilBar[0]).filter(
                (d) => d !== "Province"
              )}
              legendBottom="Province"
              legendLeft="Hist test"
              className={styles.bar}
              indexBy={"Province"}
              axisLeft={"percent"}
              enableLabel={false}
              id={"Historical testing by Province"}
            />
            <BarChart
              colorScheme={colorScheme}
              get={get.bind(
                null,
                DeviceUtilBar,
                "UtilizationByDevice(BarChart)"
              )}
              showPercentage={true}
              heading="Utilization by Equipment"
              data={DeviceUtilBar.sort(
                (a, b) => a.Capacity_Utilization - b.Capacity_Utilization
              )}
              legendBottom="Capacity Utilization"
              legendLeft="Equipment Type"
              axisRight={"percent"}
              keys={Object.keys(DeviceUtilBar[0]).filter((d, ind) => ind !== 0)}
              indexBy={"DeviceType"}
              className={styles.bar}
              isHorizontal={true}
              enableLabel
              id={"Utilization by device"}
            />
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(HistoricalTesting);
