import React, { useState, useCallback } from "react";
import Card from "../../UI/Card";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import styles from "./File.module.css";
import Loader from "../../UI/Loader";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { addScenario } from "../../../Store/ScenarioSlice";
import { Buffer } from "buffer";

import { useAuth0 } from '@auth0/auth0-react';
import {
  initializeScenario
} from "../../../Store/ScenarioSlice";

const download = function (data, name) {
  const blob = new Blob([data], { type: "application/zip" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", name + ".zip");
  a.click();
};

const processFile = async (file, currentWorksheet, fileType) => {
  let sheetData = {
    fileUploaded: true,
    currentWorksheet: currentWorksheet,
    currentWorksheetName: "",
    numOfWorkSheets: 0,
    data: { columns: [], rows: [] },
  };
  const promise = new Promise((res, rej) => {
    const fileReader = new FileReader();
    if (fileType === "excel") fileReader.readAsArrayBuffer(file);
    else fileReader.readAsBinaryString(file);
    fileReader.onload = (e) => {
      const bufferArray = e.target.result;
      let data;
      if (fileType === "excel") {
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        sheetData.numOfWorkSheets = wb.SheetNames.length;
        const wsName = wb.SheetNames[currentWorksheet];
        sheetData.currentWorksheetName = wsName;
        const ws = wb.Sheets[wsName];

        data = XLSX.utils.sheet_to_json(ws);
      } else {
        const array = bufferArray.toString().split("\n");
        let result = [];
        let headers = array[0].split(",");
        for (let i = 1; i < array.length - 1; i++) {
          let obj = {};
          let str = array[i];
          let properties = str.split(",");
          for (let j in headers) {
            if (properties[j].includes(",")) {
              obj[headers[j]] = properties[j]
                .split(",")
                .map((item) => item.trim());
            } else obj[headers[j]] = properties[j];
          }
          result.push(obj);
        }
        data = result;
      }
      res(data);
    };
    fileReader.onerror = (error) => {
      rej(error);
    };
  });

  const result = await promise.then((d) => {
    let i = 1;
    d = d.map((data) => {
      return {
        ...data,
        id: i++,
      };
    });
    if (d.length === 0) {
      sheetData = {
        ...sheetData,
        data: {
          columns: [],
          rows: [],
        },
      };
      return { ...sheetData };
    }
    const columns = [...Object.keys(d[0])].map((key) => {
      return {
        field: key,
        headerName: key,
        width: key === "id" ? 90 : 110,
      };
    });

    sheetData = {
      ...sheetData,
      data: {
        columns: [...columns],
        rows: [...d],
      },
    };
    return { ...sheetData };
  });
  return { ...result };
};

const FORM_FLOW = ["Create Scenario", "Upload and preview file"];

const File = (props) => {
  const [scenarioData, setScenarioData] = useState({
    name: "",
    createdOn: "",
    valid: false,
    tableName: "",
    FileName: "",
  });

  const [formFlow, setFormFlow] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(5);

  const dispatch = useDispatch();

  const formFlowChange = () => {
    setFormFlow((pv) => {
      return pv + 1;
    });
  };

  const onNameChange = (e) => {
    const updatedValue = e.target.value;
    setScenarioData((pv) => {
      return {
        ...pv,
        name: updatedValue,
      };
    });
  };

  function refreshPage() {
    // window.location.reload(false);
    setTimeout(getData, 1000);
  }

  const createScenario = async (e) => {
    e.preventDefault();
    setFile({});
    props.closePopup();
    dispatchAction({ type: "RESET_DATA" });
    dispatch(addScenario({ ...scenarioData }));

    refreshPage();
  };

  const { setFileName, fileData, dispatchAction, file, setFile } = props;

  const { getAccessTokenSilently } = useAuth0();

  const fileSubmitHandler = async (e) => {
    try {
      const uploadedFile = e.target.files[0];
      if (
        uploadedFile.type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        uploadedFile.type !== "application/vnd.ms-excel" &&
        uploadedFile.type !== "text/csv"
      ) {
        throw new Error(
          "Not valid file format, only CSV and XLSX file extension acceptable"
        );
      }

      if (uploadedFile.size > 10000000) {
        throw new Error(
          "File size too large, file size should be at max 10 MB"
        );
      }
      setFile(uploadedFile);
      const formData = new FormData();
      formData.append("file", uploadedFile, uploadedFile.name);

      setIsLoading(true);
      const sheetData = await processFile(
        uploadedFile,
        0,
        uploadedFile.type === "application/vnd.ms-excel" ||
          uploadedFile.type === "text/csv"
          ? "csv"
          : "excel"
      );

      const token = await getAccessTokenSilently();
      const authHeaders = {
        Authorization: `Bearer ${token}`
      }
      
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/v1/createScenario/${scenarioData.name}`,
        {
          method: "POST",
          body: formData,
          headers: authHeaders
        }
      );
      if (!response.ok) {
        const data = await response.json();
        download(Buffer.from(data.file.data), "detailedReport");
        throw data.errors;
      }
      const data = await response.json();

      setFileName(data.filename);

      setScenarioData((pv) => {
        return {
          ...pv,
          createdOn: data.createdOn,
          valid: data.valid === 1 ? true : false,
          tableName: data.tableName,
          FileName: data.filename,
        };
      });

      dispatchAction({
        type: "FILEUPLOAD",
        value: { ...sheetData },
      });
      // getData()
      // setTimeout(getData, 1000);
      setTimeout(() => setIsLoading(false), 500);
    } catch (error) {
      props.closePopup();
      props.setError({ status: true, message: error });
      setTimeout(() => setIsLoading(false), 500);
    }
  };

  const getData = useCallback(async () => {
    const token = await getAccessTokenSilently();

    const url = `${process.env.REACT_APP_API_BASE_URL}/v1/getAllScenario`;
    const headers = {
      Authorization: `Bearer ${token}`
    }
    const response = await fetch(url, {headers: headers});
    const data = await response.json();
    dispatch(initializeScenario({ ...data }));
  }, [dispatch]);

  const workSheetChangeHandler = async (opt) => {
    if (opt === 1 && fileData.currentWorksheet + 1 < fileData.numOfWorkSheets) {
      const sheet = await processFile(file, fileData.currentWorksheet + 1);
      dispatchAction({ type: "INCREMENTWORKSHEET", value: { ...sheet } });
    } else if (opt === 0 && fileData.currentWorksheet > 0) {
      const sheet = await processFile(file, fileData.currentWorksheet - 1);
      dispatchAction({ type: "DECREMENTWORKSHEET", value: { ...sheet } });
    }
  };

  return (
    <div className={styles.overlay}>
      <Card className={`${styles["available-files"]} ${styles["form-group"]}`}>
        <span
          onClick={props.closePopup}
          className={`material-icons-sharp ${styles["close-icon"]}`}
        >
          close
        </span>
        <h1>{FORM_FLOW[formFlow]}</h1>
        <form className={`${styles["form"]}`}>
          {formFlow === 0 && (
            <div className={styles.formContainer}>
              <TextField
                id="scenarioName"
                onChange={onNameChange}
                value={scenarioData.name}
                label="Scenario name"
                // error={scenarioData.name.length < 5 ? true : false}
                variant="outlined"
                className={styles["text-field"]}
                required
                helperText={""}
              />
              <button
                className={`${styles["form-btn"]} ${
                  scenarioData.name.length === 0
                    ? ""
                    : styles["form-enabled-btn"]
                }`}
                onClick={formFlowChange}
                disabled={scenarioData.name.length === 0}
              >
                <span className="material-icons-sharp">arrow_forward</span>
              </button>
            </div>
          )}
          {formFlow === 1 && (
            <>
              {!fileData.fileUploaded && !isLoading && (
                <label className={styles.uploadFile}>
                  Upload file{" "}
                  {isLoading && (
                    <span
                      className={`material-icons-sharp ${styles.loadingIcon}`}
                    >
                      refresh
                    </span>
                  )}
                  <input onChange={fileSubmitHandler} type={"file"} />
                </label>
              )}

              {isLoading ? (
                <Loader className={styles.loader} />
              ) : !fileData.fileUploaded ? (
                ""
              ) : (
                <>
                  <button
                    className={`${styles["form-btn"]} ${
                      scenarioData.name.length === 0
                        ? ""
                        : styles["form-enabled-btn"]
                    }`}
                    onClick={createScenario}
                  >
                    Create Scenario
                  </button>
                  <div className={styles.options}>
                    <span className={styles.title}>
                      {"Title: " +
                        (fileData.currentWorksheetName === ""
                          ? "No sheet selected"
                          : fileData.currentWorksheetName)}
                    </span>
                    <div className={styles.navigator}>
                      <span
                        onClick={workSheetChangeHandler.bind(null, 0)}
                        className={`material-icons-sharp ${styles.icon}`}
                      >
                        arrow_left
                      </span>
                      {fileData.currentWorksheet +
                        (fileData.fileUploaded ? 1 : 0) +
                        " of " +
                        fileData.numOfWorkSheets}
                      <span
                        onClick={workSheetChangeHandler.bind(null, 1)}
                        className={`material-icons-sharp ${styles.icon}`}
                      >
                        arrow_right
                      </span>
                    </div>
                  </div>
                  <Box sx={{ height: 400, width: 1200 }}>
                    <DataGrid
                      rows={fileData.data.rows}
                      columns={fileData.data.columns}
                      pageSize={pageSize}
                      rowsPerPageOptions={[5, 10, 15]}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      checkboxSelection
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                    />
                  </Box>
                </>
              )}
            </>
          )}
          {/* <label htmlFor="name">Scenario name</label>
          <input id="name" name="name" type={"text"} />
          <Button
            disabled={isLoading}
            className={`${isLoading ? styles.disabled : styles.btn}`}
            onClick={handleClick}
          >
            Run analysis
          </Button> */}
        </form>
        {/* <ul>
          {fileData.fileUploaded && (
            <li className={styles.fileData}>
              <div className={`${styles.fileCard} ${styles.selected}`}>
                <span
                  className={`material-icons-sharp ${styles.icon} ${styles.selectedIcon}`}
                >
                  insert_drive_file
                </span>
                <span className={styles.name}>
                  {fileName.substring(0, 10) + " ..."}
                </span>
              </div>
            </li>
          )}
        </ul> */}
      </Card>
    </div>
  );
};

export default File;
