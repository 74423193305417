import React from "react";
import styles from "./Option.module.css";
const Option = (props) => {
  return (
    <li
      onClick={props.optionChange}
      className={props.selected ? styles.selected : null}
    >
      {props.children}
    </li>
  );
};

export default Option;
