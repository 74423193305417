import { ResponsivePie } from "@nivo/pie";
import Card from "../../../UI/Card";
import styles from "./PieChart.module.css";

const MyResponsivePie = (props) => (
  <div id={props.id} className={styles["pie-chart"]}>
    <ResponsivePie
      data={props.data}
      margin={{ top: 70, right: 120, bottom: 30, left: 120 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={0}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      arcLinkLabelsStraightLength={5}
      arcLabel={(d) =>
        `${d.value >= 1000 ? (d.value / 1000).toFixed(0) + "K" : d.value}`
      }
      colors={props.data.map((d) => d.id).map((d) => props.colorScheme[d])}
      // fit
      // defs={[
      //   {
      //     id: "dots",
      //     type: "patternDots",
      //     background: "inherit",
      //     color: "rgba(255, 255, 255, 0.3)",
      //     size: 4,
      //     padding: 1,
      //     stagger: true,
      //   },
      //   {
      //     id: "lines",
      //     type: "patternLines",
      //     background: "inherit",
      //     color: "rgba(255, 255, 255, 0.3)",
      //     rotation: -45,
      //     lineWidth: 6,
      //     spacing: 10,
      //   },
      // ]}
      // fill={[
      //   {
      //     match: {
      //       id: "ruby",
      //     },
      //     id: "dots",
      //   },
      //   {
      //     match: {
      //       id: "c",
      //     },
      //     id: "dots",
      //   },
      //   {
      //     match: {
      //       id: "go",
      //     },
      //     id: "dots",
      //   },
      //   {
      //     match: {
      //       id: "python",
      //     },
      //     id: "dots",
      //   },
      //   {
      //     match: {
      //       id: "scala",
      //     },
      //     id: "lines",
      //   },
      //   {
      //     match: {
      //       id: "lisp",
      //     },
      //     id: "lines",
      //   },
      //   {
      //     match: {
      //       id: "elixir",
      //     },
      //     id: "lines",
      //   },
      //   {
      //     match: {
      //       id: "javascript",
      //     },
      //     id: "lines",
      //   },
      // ]}
      // legends={[
      //   {
      //     anchor: "bottom",
      //     direction: "column",
      //     justify: false,
      //     translateX: 170,
      //     translateY: 56,
      //     itemsSpacing: 0,
      //     itemWidth: 50,
      //     itemHeight: 18,
      //     itemTextColor: "#999",
      //     itemDirection: "left-to-right",
      //     itemOpacity: 1,
      //     symbolSize: 18,
      //     effects: [
      //       {
      //         on: "hover",
      //         style: {
      //           itemTextColor: "#000",
      //         },
      //       },
      //     ],
      //   },
      // ]}
    />
  </div>
);
const svgToPng = function (svgText, margin, fill) {
  // convert an svg text to png using the browser
  return new Promise(function (resolve, reject) {
    try {
      // can use the domUrl function from the browser
      var domUrl = window.URL || window.webkitURL || window;
      if (!domUrl) {
        throw new Error("(browser doesnt support this)");
      }

      // figure out the height and width from svg text
      var match = svgText.match(/height=\"(\d+)/m);
      var height = match && match[1] ? parseInt(match[1], 10) : 200;
      var match = svgText.match(/width=\"(\d+)/m);
      var width = match && match[1] ? parseInt(match[1], 10) : 200;
      margin = margin || 0;

      // it needs a namespace
      if (!svgText.match(/xmlns=\"/im)) {
        svgText = svgText.replace(
          "<svg ",
          '<svg xmlns="http://www.w3.org/2000/svg" '
        );
      }

      // create a canvas element to pass through
      var canvas = document.createElement("canvas");
      canvas.innerHTML = "<h1>Pie chart</h1>";
      canvas.width = height + margin * 2;
      canvas.height = width + margin * 2;
      var ctx = canvas.getContext("2d");

      // make a blob from the svg
      var svg = new Blob([svgText], {
        type: "image/svg+xml;charset=utf-8",
      });

      // create a dom object for that image
      var url = domUrl.createObjectURL(svg);

      // create a new image to hold it the converted type
      var img = new Image();

      // when the image is loaded we can get it as base64 url
      img.onload = function () {
        // draw it to the canvas
        ctx.drawImage(this, 0, 0);

        // if it needs some styling, we need a new canvas
        if (fill) {
          var styled = document.createElement("canvas");
          styled.width = canvas.width;
          styled.height = canvas.height;
          var styledCtx = styled.getContext("2d");
          styledCtx.save();
          styledCtx.fillStyle = fill;
          styledCtx.fillRect(0, 0, canvas.width, canvas.height);
          styledCtx.strokeRect(0, 0, canvas.width, canvas.height);
          styledCtx.restore();
          styledCtx.drawImage(canvas, 0, 0);
          canvas = styled;
        }
        // we don't need the original any more
        domUrl.revokeObjectURL(url);
        // now we can resolve the promise, passing the base64 url
        resolve(canvas.toDataURL());
      };

      // load the image
      img.src = url;
    } catch (err) {
      reject("failed to convert svg to png " + err);
    }
  });
};
const PieChart = (props) => {
  const downloadPng = async (e) => {
    const svg = document.getElementById(props.id).children[0].children[0]
      .innerHTML;
    const a = await svgToPng(svg, 100, "white");
    var dlLink = document.createElement("a");
    dlLink.download = props.id;
    dlLink.href = a;
    dlLink.dataset.downloadurl = [
      "image/png",
      dlLink.download,
      dlLink.href,
    ].join(":");
    document.body.appendChild(dlLink);
    dlLink.click();
    document.body.removeChild(dlLink);
  };
  return (
    <Card className={styles.pie}>
      <div className={styles["pie-heading"]}>
        <h2>{props.heading}</h2>
        <div>
          <span
            onClick={downloadPng}
            style={{
              cursor: "pointer",
              fontSize: "1.4rem",
              marginRight: "10px",
            }}
            className="material-icons-sharp"
          >
            add_a_photo
          </span>
          <span
            className="material-icons-sharp"
            style={{ cursor: "pointer", fontSize: "1.4rem" }}
            onClick={props.get}
          >
            download
          </span>
        </div>
      </div>
      <MyResponsivePie {...props} />
    </Card>
  );
};
export default PieChart;
