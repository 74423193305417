import React, { useCallback, useState, useEffect } from "react";
import Button from "../../../UI/Button";
import styles from "./Filter.module.css";

import { useAuth0 } from "@auth0/auth0-react";

const Filter = (props) => {
  const [filter, setFilter] = useState({
    Origin: [],
    // Lab: [],
    "Test Type": [],
    // locationType: ["Health Facility", "Lab", "Hubs"],
    "Referral type": [],
    Origin_Province: [],
    Origin_District: [],
  });

  const [formData, setFormData] = useState({
    origin: "",
    testType: "",
    origin_province: "",
    origin_district: "",
    referralType: "HF_LAB",
    rank: 1,
  });

  const [largeFileState, setLargeFileState] = useState({
    status: true,
    isChanged: false,
  });

  const { fileName } = props;

  const { getAccessTokenSilently } = useAuth0();

  const getFilters = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const authHeaders = {
      Authorization: `Bearer ${token}`,
    };
    const url = `${process.env.REACT_APP_API_BASE_URL}/v1/refferalFilter/${fileName}/${formData.referralType}?Province=${formData.origin_district}&AdminArea=${formData.origin_province}&Origin=${formData.origin}`;

    const response = await fetch(url, { headers: authHeaders });
    const data = await response.json();
    if (data.isDataFrameLarge && !largeFileState.isChanged) {
      setLargeFileState({ status: true, isChanged: true });
    }
    setFilter({ ...data });
  }, [
    fileName,
    formData.referralType,
    formData.origin_district,
    formData.origin_province,
    formData.origin,
    largeFileState.isChanged,
  ]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  const originChangeHandler = (e) => {
    const updatedValue = e.target.value;
    setFormData((pv) => {
      return {
        ...pv,
        origin: updatedValue,
        testType: "",
      };
    });
  };
  const testTypeChangeHandler = (e) => {
    const updatedValue = e.target.value;
    setFormData((pv) => {
      return {
        ...pv,
        testType: updatedValue,
      };
    });
  };
  const provinceChangeHandler = (e) => {
    const updatedValue = e.target.value;
    setFormData((pv) => {
      return {
        ...pv,
        origin_province: updatedValue,
        origin: "",
        testType: "",
      };
    });
  };
  const districtChangeHandler = (e) => {
    const updatedValue = e.target.value;
    setFormData((pv) => {
      return {
        ...pv,
        origin_district: updatedValue,
      };
    });
    setFormData((pv) => {
      return {
        ...pv,
        origin_province: "",
        origin: "",
        testType: "",
      };
    });
  };
  const referralTypeChangeHandler = (e) => {
    const updatedValue = e.target.value;
    setFormData((pv) => {
      return {
        origin: "",
        testType: "",
        origin_province: "",
        origin_district: "",
        radius: 0,
        referralType: updatedValue,
        rank: 1,
      };
    });
  };

  const rankChangeHandler = (e) => {
    const updatedValue = e.target.value;
    setFormData((pv) => {
      return {
        ...pv,
        rank: +updatedValue,
      };
    });
  };

  return (
    <div className={styles["filter-container"]}>
      <div className={styles.filter}>
        <label htmlFor="referralType">Flow type</label>
        <select
          value={formData.referralType}
          onChange={referralTypeChangeHandler}
          name="referralType"
          id="referralType"
        >
          {filter["Referral type"].map((filter, ind) => (
            <option key={ind} value={filter}>
              {filter === "HF_LAB"
                ? "Customer to Plant"
                : filter === "HUB_LAB"
                ? "Hub to Lab"
                : "Customer to Warehouse"}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.filter}>
        <label htmlFor="Origin_District">Province </label>
        <select
          value={formData.origin_district}
          onChange={districtChangeHandler}
          name="Origin_District"
          id="Origin_District"
        >
          {!largeFileState.status && <option value="">All</option>}
          {filter.Origin_District.map((filter, ind) => (
            <option key={ind} value={filter}>
              {filter}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.filter}>
        <label htmlFor="Origin_Province">District </label>
        <select
          value={formData.origin_province}
          onChange={provinceChangeHandler}
          name="Origin_Province"
          id="Origin_Province"
        >
          <option value="">All</option>
          {filter.Origin_Province.map((filter, ind) => (
            <option key={ind} value={filter}>
              {filter}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.filter}>
        <label htmlFor="origin">Origin</label>
        <select
          value={formData.origin}
          onChange={originChangeHandler}
          name="origin"
          id="origin"
        >
          <option value="">All</option>
          {filter.Origin.map((filter, ind) => (
            <option key={ind} value={filter}>
              {filter}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.filter}>
        <label htmlFor="Test type">Product: </label>
        <select
          value={formData.testType}
          onChange={testTypeChangeHandler}
          name="Test type"
          id="Test type"
        >
          <option value="">All</option>
          {filter["Test Type"].map((filter, ind) => (
            <option key={ind} value={filter}>
              {filter}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.filter}>
        <label style={{ display: "block" }} htmlFor="rank">
          {"Rank : " + formData.rank}
        </label>
        <input
          className={styles["rank-filter"]}
          type={"range"}
          id={"rank"}
          min={1}
          max={5}
          step={1}
          value={formData.rank}
          onChange={rankChangeHandler}
        />
      </div>

      <Button
        onClick={props.changeFilter.bind(null, formData)}
        className={styles.btn}
      >
        Apply
      </Button>
    </div>
  );
};

export default Filter;
