import { useSelector, useDispatch } from "react-redux";
import { throwError, acknowledgeError } from "../Store/errorSlice";
import { useCallback } from "react";

const useError = () => {
  const dispatch = useDispatch();

  const { status, messages } = useSelector((state) => state.error);

  const modifyError = useCallback(
    (error) => {
      let newError = {};
      if (
        error.message instanceof Error ||
        error.message instanceof TypeError
      ) {
        newError = {
          status: true,
          messages: [error.message.message],
        };
      } else if (!error.message) {
        newError = {
          status: true,
          messages: ["Something went wrong. Check uploaded file"],
        };
      } else {
        newError = {
          status: true,
          messages: [...error.message],
        };
      }
      dispatch(throwError(newError));
    },
    [dispatch]
  );

  const closeError = useCallback(() => {
    dispatch(acknowledgeError());
  }, [dispatch]);

  return {
    status,
    messages,
    closeError,
    modifyError,
  };
};

export default useError;
