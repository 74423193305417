import Card from "../../../UI/Card";
import styles from "./HeatMap.module.css";
import { useState } from "react";
import Button from "../../../UI/Button";
import React from "react";
import { ResponsiveHeatMap } from "@nivo/heatmap";

const MyResponsivePie = (props) => (
  <div id={props.id} className={styles["pie-chart"]}>
    <ResponsiveHeatMap
      data={props.data}
      margin={{ top: 60, right: 40, bottom: 60, left: 160 }}
      valueFormat=">-.2s"
      // axisTop={{
      //   tickSize: 5,
      //   tickPadding: 5,
      //   tickRotation: -90,
      //   legend: "",
      //   legendOffset: 46,
      // }}
      // axisRight={{
      //   tickSize: 5,
      //   tickPadding: 5,
      //   tickRotation: 0,
      //   legend: "Test type",
      //   legendPosition: "middle",
      //   legendOffset: 130,
      // }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Product Type",
        legendPosition: "middle",
        legendOffset: -130,
      }}
      colors={{
        type: "diverging",
        scheme: "cool",
        divergeAt: 0.5,
        minValue: -100000,
        maxValue: 100000,
      }}
      emptyColor="#555555"
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 3]],
      }}
      legends={[
        {
          anchor: "bottom",
          translateX: -60,
          translateY: 30,
          length: 250,
          thickness: 8,
          direction: "row",
          tickPosition: "after",
          tickSize: 3,
          tickSpacing: 4,
          tickOverlap: false,
          tickFormat: ">-.2s",
          title: "Demand →",
          titleAlign: "start",
          titleOffset: 4,
        },
      ]}
      inactiveOpacity={0.55}
    />
  </div>
);
const svgToPng = function (svgText, margin, fill) {
  // convert an svg text to png using the browser
  return new Promise(function (resolve, reject) {
    try {
      // can use the domUrl function from the browser
      var domUrl = window.URL || window.webkitURL || window;
      if (!domUrl) {
        throw new Error("(browser doesnt support this)");
      }

      // figure out the height and width from svg text
      var match = svgText.match(/height=\"(\d+)/m);
      var height = match && match[1] ? parseInt(match[1], 10) : 200;
      var match = svgText.match(/width=\"(\d+)/m);
      var width = match && match[1] ? parseInt(match[1], 10) : 200;
      margin = margin || 0;

      // it needs a namespace
      if (!svgText.match(/xmlns=\"/im)) {
        svgText = svgText.replace(
          "<svg ",
          '<svg xmlns="http://www.w3.org/2000/svg" '
        );
      }

      // create a canvas element to pass through
      var canvas = document.createElement("canvas");
      canvas.innerHTML = "<h1>Pie chart</h1>";
      canvas.width = height + margin * 2;
      canvas.height = width + margin * 2;
      var ctx = canvas.getContext("2d");

      // make a blob from the svg
      var svg = new Blob([svgText], {
        type: "image/svg+xml;charset=utf-8",
      });

      // create a dom object for that image
      var url = domUrl.createObjectURL(svg);

      // create a new image to hold it the converted type
      var img = new Image();

      // when the image is loaded we can get it as base64 url
      img.onload = function () {
        // draw it to the canvas
        ctx.drawImage(this, 0, 0);

        // if it needs some styling, we need a new canvas
        if (fill) {
          var styled = document.createElement("canvas");
          styled.width = canvas.width;
          styled.height = canvas.height;
          var styledCtx = styled.getContext("2d");
          styledCtx.save();
          styledCtx.fillStyle = fill;
          styledCtx.fillRect(0, 0, canvas.width, canvas.height);
          styledCtx.strokeRect(0, 0, canvas.width, canvas.height);
          styledCtx.restore();
          styledCtx.drawImage(canvas, 0, 0);
          canvas = styled;
        }
        // we don't need the original any more
        domUrl.revokeObjectURL(url);
        // now we can resolve the promise, passing the base64 url
        resolve(canvas.toDataURL());
      };

      // load the image
      img.src = url;
    } catch (err) {
      reject("failed to convert svg to png " + err);
    }
  });
};
const HeatMap = (props) => {
  const [radius, setRadius] = useState(0);

  const colors = [];
  const devices = Object.keys(props.colorScheme);
  for (let i = 0; i < devices.length; i++) {
    colors[i] = props.colorScheme[devices[i]];
  }

  const downloadPng = async () => {
    const svg = document.getElementById(props.id).children[0].children[0]
      .innerHTML;
    const a = await svgToPng(svg, 100, "white");
    var dlLink = document.createElement("a");
    dlLink.download = props.id;
    dlLink.href = a;
    dlLink.dataset.downloadurl = [
      "image/png",
      dlLink.download,
      dlLink.href,
    ].join(":");
    document.body.appendChild(dlLink);
    dlLink.click();
    document.body.removeChild(dlLink);
  };

  const radiusChangeHandler = (e) => {
    const updatedValue = e.target.value;
    setRadius(+updatedValue);
  };
  return (
    <Card className={styles.pie}>
      <div className={styles["pie-heading"]}>
        <h2>{props.heading}</h2>
        <div>
          <span
            onClick={downloadPng}
            style={{
              cursor: "pointer",
              fontSize: "1.4rem",
              marginRight: "10px",
            }}
            className="material-icons-sharp"
          >
            add_a_photo
          </span>
          <span
            className="material-icons-sharp"
            style={{ cursor: "pointer", fontSize: "1.4rem" }}
            onClick={props.get}
          >
            download
          </span>
        </div>
      </div>
      <div className={styles.filter}>
        <label htmlFor="Radius">Radius</label>
        <div className={styles["input-container"]}>
          <input
            value={Number(radius).toString()}
            onChange={radiusChangeHandler}
            name="Radius"
            id="Radius"
            type={"number"}
            className={styles.textInput}
          />
          <Button
            onClick={props.changeRadius.bind(null, radius)}
            className={styles.btn}
          >
            Run
          </Button>
        </div>
      </div>
      <MyResponsivePie {...props} />
    </Card>
  );
};
export default React.memo(HeatMap);
