import React from "react";
import ReactDOM from "react-dom";
import styles from "./ErrorOverlay.module.css";
import { Box } from "@mui/system";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Card from "./Card";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const Error = (props) => {
  return (
    <div className={styles.errorOverlay}>
      <Card className={styles.error}>
        <h1>
          Errors <span className="material-symbols-outlined">error</span>
          <span
            onClick={props.onClick}
            className={`material-icons-sharp ${styles.close}`}
          >
            close
          </span>
        </h1>
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            components={{ Toolbar: CustomToolbar }}
            rows={props.messages.map((message, ind) => {
              return {
                id: ind + 1,
                error: message,
              };
            })}
            columns={[
              { field: "id", headerName: "S.no", width: 50 },
              { field: "error", headerName: "Error message", width: 700 },
            ]}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      </Card>
    </div>
  );
};

const ErrorOverlay = (props) => {
  return ReactDOM.createPortal(
    <Error onClick={props.onClick} messages={props.messages} />,
    document.getElementById("errorOverlay")
  );
};

export default ErrorOverlay;
