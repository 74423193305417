import React from "react";
import styles from "./Bar.module.css";
import Card from "../../../UI/Card";
import { ResponsiveBar } from "@nivo/bar";

const MyResponsiveBar = (props) => {
  console.log(props.keys);
  return (
    <ResponsiveBar
      data={props.data}
      keys={props.keys}
      indexBy={props.indexBy}
      margin={{ top: 30, right: 150, bottom: 90, left: 80 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "set3" }}
      layout={props.isHorizontal ? "horizontal" : "vertical"}
      // borderWidth="1px"
      // borderRadius={"2px"}
      enableLabel={props.enableLabel}
      // defs={[
      //   {
      //     id: "dots",
      //     type: "patternDots",
      //     background: "inherit",
      //     color: "#38bcb2",
      //     size: 4,
      //     padding: 1,
      //     stagger: true,
      //   },
      //   {
      //     id: "lines",
      //     type: "patternLines",
      //     background: "inherit",
      //     color: "#eed312",
      //     rotation: -45,
      //     lineWidth: 6,
      //     spacing: 10,
      //   },
      // ]}
      // fill={[
      //   {
      //     match: {
      //       id: props.keys[0],
      //     },
      //     id: "dots",
      //   },
      //   {
      //     match: {
      //       id: props.keys[1],
      //     },
      //     id: "lines",
      //   },
      // ]}
      borderColor={"#222222"}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        format: (v) => {
          return v.length > 7 ? (
            <tspan>
              {v.substring(0, 3) + "..."}
              <title>{v + (props.showPercentage ? "%" : "")}</title>
            </tspan>
          ) : (
            v + (props.showPercentage ? "%" : "")
          );
        },
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -30,
        legend: props.legendBottom,
        legendPosition: "middle",
        legendOffset: 50,
      }}
      axisLeft={{
        format: (v) => {
          return v.length > 7 ? (
            <tspan>
              {v.substring(0, 3) + "..."}
              <title>{v + (props.showPercentage ? "%" : "")}</title>
            </tspan>
          ) : (
            v
          );
        },
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: props.legendLeft,
        legendPosition: "middle",
        legendOffset: -60,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={"white"}
      label={(d) => `${d.value + (props.showPercentage ? "%" : "")}`}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 115,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      ariaLabel={props.heading}
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in Province: " + e.indexValue;
      }}
      enableGridY={false}
      // tooltip={({ id, indexValue, value, color }) => (
      //   <div
      //     style={{
      //       padding: 12,
      //       color,
      //       background: "#222222",
      //     }}
      //   >
      //     <strong>
      //       {id +
      //         " of " +
      //         indexValue +
      //         " : " +
      //         value +
      //         (props.showPercentage ? "%" : "")}
      //     </strong>
      //   </div>
      // )}
    />
  );
};

const BarChart = (props) => {
  console.log(props);

  return (
    <Card className={` ${props.className}`}>
      {/* <Bar
        data={{
          labels: props.labels,
          datasets: [
            {
              ...DEFAULT_BAR_STYLING,
              label: props.label,
              data: props.data,
            },
          ],
        }}
        options={props.options}
      /> */}
      <div className={styles["bar-heading"]}>
        <h2>{props.heading}</h2>
      </div>
      <MyResponsiveBar {...props} />
    </Card>
  );
};

export default BarChart;
