import React, { useState, useReducer, useCallback, useMemo } from "react";
import HistoricalTesting from "./Dashboard/HistoricalTesting";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import Scenario from "./Pages/Scenario/Scenario";
import styles from "./Dashboard.module.css";
import Loader from "./UI/Loader";
import ErrorOverlay from "./UI/ErrorOverlay";
import ErrorBoundary from "./Utils/ErrorBoundary";
import Referral from "./ReferralLinkage/Referral.jsx";
import NetworkSummary from "./Historical Summary/NetworkSummary";
import HistoricalRefferal from "./Historical Referral/HistoricalReferral";
import Demand from "./Demand/Demand";
import useError from "../hooks/use-error";
import Future from "./Future/Future";

const DEFAULT_FILE = {
  fileUploaded: false,
  currentWorksheet: 0,
  currentWorksheetName: "",
  numOfWorkSheets: 0,
  data: { columns: [], rows: [] },
  scenarioName: "",
};

const fileReducer = (state, action) => {
  // This is reducer function
  const { type } = action;
  if (type === "FILEUPLOAD") {
    return action.value;
  } else if (type === "INCREMENTWORKSHEET") {
    return {
      ...action.value,
    };
  } else if (type === "DECREMENTWORKSHEET") {
    return {
      ...action.value,
    };
  } else if (type === "RESET_DATA") return DEFAULT_FILE;
  else return state;
};
/**
 * This is the main section of analytics app
 * This componenet renders the selected tab
 *
 * WORKFLOW - User selects a file by clicking 'view results' on scenario tab(by default selected tab)
 * User is then redirected to Demand-capacity tab
 */

const DashboardV2 = () => {
  const [isCollapsed, setIsCollapsed] = useState(false); // If sidebar is collapsed or not
  const [selectedPage, setSelectedPage] = useState(0); // Selected Tab (refer PAGES array below)
  const [filename, setFilename] = useState(""); // selected file's fileName
  const [fileData, dispatchAction] = useReducer(fileReducer, DEFAULT_FILE); // Data of file required  to be previewed at time of upload

  const { status, messages, closeError, modifyError } = useError();

  // Function to change fileName state
  const fileNameChangeHandler = useCallback((newName) => {
    setFilename(newName);
  }, []);

  // Function to set error
  const changeError = useCallback(
    (error) => {
      modifyError(error);
      setSelectedPage(0);
    },
    [modifyError]
  );

  // Function to change selected tab (selectedPage)
  const setPage = useCallback((id) => {
    setSelectedPage(id);
  }, []);

  const PAGES = [
    // Available Scenario
    <Scenario
      setError={changeError}
      setPage={setPage}
      fileName={useMemo(() => filename, [filename])}
      setFileName={fileNameChangeHandler}
      fileData={useMemo(() => fileData, [fileData])}
      dispatchAction={dispatchAction}
    />,
    // Potential Referral linkages
    <Referral setError={changeError} fileName={filename} />,
    // Historical Testing
    <HistoricalTesting
      fileName={filename}
      setPage={setPage}
      setError={changeError}
    />,
    // network summary
    <NetworkSummary
      fileName={filename}
      setPage={setPage}
      setError={changeError}
    />,
    // Historical Refferal
    <HistoricalRefferal setError={changeError} fileName={filename} />,
    // Loading screen
    <Loader className={styles.loader} />,
    // Capacity comparison
    <Demand setError={changeError} fileName={filename} />,
    // Future testing tab
    <Future setError={changeError} fileName={filename} setPage={setPage} />,
  ];

  const collapseHandler = useCallback(() => {
    setIsCollapsed((pv) => !pv);
  }, []);

  return (
    <>
      {status && <ErrorOverlay onClick={closeError} messages={messages} />}

      <Navbar isCollapsed={isCollapsed} />
      <Sidebar
        activePage={selectedPage}
        setSelectedOption={setPage}
        isCollapsed={isCollapsed}
        collapseHandler={collapseHandler}
      />
      <main className={isCollapsed ? styles.Collpased : undefined}>
        <ErrorBoundary setError={changeError} setPage={setPage}>
          {PAGES[selectedPage]}
        </ErrorBoundary>
      </main>
    </>
  );
};

export default DashboardV2;
