import React from "react";
import Card from "../../UI/Card";
import styles from "./Insights.module.css";

const Insights = (props) => {
  let totalDemand = props.insights["Total Demand"];
  let numberOfDevices = props.insights["Number of devices"];
  let maxCapacity = props.insights["Max Capacity"];
  let Utilization = props.insights["Utilization"];

  return (
    <div className={styles.insights}>
      <Card className={styles["insight-card"]}>
        <div className={styles.middle}>
          <div className={styles.left}>
            <h1>
              {totalDemand
                ? totalDemand >= 1000000
                  ? (totalDemand / 1000000).toFixed(2) + "M"
                  : totalDemand > 1000
                  ? new Intl.NumberFormat("en-us").format(totalDemand)
                  : totalDemand
                : "No data"}
            </h1>
            <span className={styles.tagline}>Total Demand</span>
          </div>
          <span
            className="material-icons-sharp"
            style={{ fontSize: "80px", color: "#12a6ad" }}
          >
            connect_without_contact
          </span>
        </div>
      </Card>
      <Card className={styles["insight-card"]}>
        <div className={styles.middle}>
          <div className={styles.left}>
            <h1>
              {numberOfDevices
                ? numberOfDevices >= 1000000
                  ? (numberOfDevices / 1000000).toFixed(0) + "M"
                  : numberOfDevices > 1000
                  ? new Intl.NumberFormat("en-us").format(numberOfDevices)
                  : numberOfDevices
                : "No data"}
            </h1>
            <span className={styles.tagline}>Total Equipments Available</span>
          </div>

          <span
            className="material-icons-sharp"
            style={{
              fontSize: "80px",
              color: "#12a6ad",
            }}
          >
            biotech
          </span>
        </div>
      </Card>
      <Card
        onClick={props.get}
        className={`${styles.downloadCard} ${styles["insight-card"]}`}
      >
        <div className={styles.middle}>
          <div className={styles.left}>
            <h1>
              {Utilization
                ? Utilization >= 1000000
                  ? (Utilization / 1000000).toFixed(2) + "M"
                  : Utilization > 1000
                  ? Intl.NumberFormat("en-us").format(Utilization)
                  : Utilization + "%"
                : Utilization + "%"}
            </h1>
            <span className={styles.tagline}>Utilization</span>
          </div>
          <span
            class="material-symbols-outlined"
            style={{
              fontSize: "80px",
              color: "#12a6ad",
            }}
          >
            clock_loader_10
          </span>
        </div>
      </Card>
      <Card
        onClick={props.get}
        className={`${styles.downloadCard} ${styles["insight-card"]}`}
      >
        <div className={styles.middle}>
          <div className={styles.left}>
            <h1>
              {maxCapacity
                ? maxCapacity >= 1000000
                  ? (maxCapacity / 1000000).toFixed(2) + "M"
                  : maxCapacity > 1000
                  ? Intl.NumberFormat("en-us").format(maxCapacity)
                  : maxCapacity
                : "No data"}
            </h1>
            <span className={styles.tagline}>Total Capacity Available</span>
          </div>

          <span
            className="material-icons-sharp"
            style={{
              fontSize: "80px",
              color: "#12a6ad",
            }}
          >
            reduce_capacity
          </span>
        </div>
      </Card>
    </div>
  );
};

export default Insights;
