import React, { useState, useCallback, useEffect } from "react";
import styles from "./NetworkSummary.module.css";
import BarChart from "./Charts/Bar/Bar";
import Filter from "./Filter/Filter";
import Map from "./Charts/Map/Map";
import Loader from "../UI/Loader";

import { useAuth0 } from '@auth0/auth0-react';

const NetworkSummary = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState({ province: "", deviceType: "" });
  const [provinceUtilBar, setProvinceUtilBar] = useState([]);
  const [DeviceUtilBar, setDeviceUtilBar] = useState([]);
  const [mapData, setMapData] = useState({ mapData: [], center: [0, 0] });

  const { fileName, setError, setPage } = props;

  const { getAccessTokenSilently } = useAuth0();

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      if (fileName === "") {
        const message = ["No file selected"];
        throw message;
      }
      
      const token = await getAccessTokenSilently();
      const url = `${process.env.REACT_APP_API_BASE_URL}/v2/HistoricalTesting/${fileName}?province=${filter.province}&device=${filter.deviceType}`;
      const authHeaders = {
        Authorization: `Bearer ${token}`
      }

      const response = await fetch(url, {headers: authHeaders});
      const data = await response.json();
      if (!response.ok) throw data.errors;
      if (!data.map.center[0] || !data.map.center[1]) {
        setMapData((pv) => {
          return { mapData: data.map.mapData, center: pv.center };
        });
      } else setMapData({ mapData: data.map.mapData, center: data.map.center });
      setProvinceUtilBar(data.provinceCapcityUtil);
      setDeviceUtilBar(data.deviceCapacityUtil);
      setIsLoading(false);
    } catch (error) {
      setError({ status: true, message: error });
    }
  }, [filter, fileName, setError, setIsLoading]);

  useEffect(() => {
    getData();
  }, [getData, filter]);

  const filterChangeHandler = useCallback(
    (updatedValues) => {
      setFilter({ ...updatedValues });
    },
    [setFilter]
  );

  const throwError = (error) => {
    setError({ status: true, message: error.message });
    setPage(0);
  };

  return (
    <>
      {isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <>
          <Filter
            setError={setError}
            province={filter.province}
            deviceType={filter.deviceType}
            fileName={fileName}
            changeFilter={filterChangeHandler}
          />
          <Map
            setError={throwError}
            mapData={[...mapData.mapData]}
            center={[...mapData.center]}
          />
          <div className={styles.barContainer}>
            <BarChart
              heading="Province Type Hist test"
              data={provinceUtilBar}
              keys={Object.keys(provinceUtilBar[0]).filter(
                (d) => d !== "Province"
              )}
              legendBottom="Province"
              legendLeft="Hist test"
              className={styles.bar}
              indexBy={"Province"}
              axisLeft={"percent"}
              enableLabel={false}
            />
            <BarChart
              showPercentage={true}
              heading="Device Type Utilization"
              data={DeviceUtilBar}
              legendBottom="Device Type"
              legendLeft="Capacity Utilization"
              axisRight={"percent"}
              keys={Object.keys(DeviceUtilBar[0]).filter((d, ind) => ind !== 0)}
              indexBy={"DeviceType"}
              className={styles.bar}
              isHorizontal={true}
              enableLabel
            />
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(NetworkSummary);
