import React, { useEffect, useCallback, useState } from "react";
import Card from "../UI/Card";
import Map from "./Charts/Map/Map";
import styles from "./HistoricalReferral.module.css";
import Filter from "./Charts/Map/Filter";
import Bar from "./Charts/Bar/Bar.jsx";
import Insights from "./Insights/Insights";

import { useAuth0 } from '@auth0/auth0-react';

const download = function (data, name) {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", name + ".csv");
  a.click();
};

const csvmaker = function (data) {
  let csvRows = [];
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(","));
  for (let i = 0; i < data.length; i++) {
    const values = Object.values(data[i]).join(",");
    csvRows.push(values);
  }
  return csvRows.join("\n");
};

const schemeColors = [
  "#9E0142",
  "#66C2A5",
  "#3288BD",
  "#F46D43",
  "#CAC111",
  "#5E4FA2",
  "#FDAE61",
  "#CAF510",
  "#FEE08B",
  "#D53E4F",
  "#FFFF30",
];

const HistoricalRefferal = (props) => {
  const [colorScheme, setColorScheme] = useState({});
  const [insights, setInsights] = useState({
    average: 0,
    total: 0,
  });
  const [mapData, setMapData] = useState({
    geoJson: {
      type: "features",
      features: [],
      devices: [],
    },
    center: [0, 0],
  });

  const [distanceAnalysis, setdistanceAnalysis] = useState({
    columns: [],
    data: [],
  });
  const [barData, setbarData] = useState([]);
  const [barData2, setbarData2] = useState([]);

  const [filterData, setFilterData] = useState({
    origin: "",
    testType: "",
    origin_province: "",
    origin_district: "",
    referralType: "HF_LAB",
    changeScheme: true,
  });

  const { fileName, setError } = props;

  const { getAccessTokenSilently } = useAuth0();

  const getData = useCallback(async () => {
    try {
      if (fileName === "") {
        const message = ["No file selected"];
        throw message;
      }

      const token = await getAccessTokenSilently();

      const url = `${process.env.REACT_APP_API_BASE_URL}/v1/historicalRefferalFilterValues?fileName=${fileName}&Health_Facility=${filterData.origin}&TestType=${filterData.testType}&Province=${filterData.origin_province}&District=${filterData.origin_district}&referralType=${filterData.referralType}`;
      const authHeaders = {
        Authorization: `Bearer ${token}`
      }

      const response = await fetch(url, {headers: authHeaders});
      const data = await response.json();
      if (!response.ok) {
        throw data.errors;
      }
      setdistanceAnalysis(data.table2);
      setbarData(data.pie);
      setbarData2(data.bar2);
      setTimeout(() => {
        setMapData((pv) => {
          return {
            geoJson: data.map,
            center:
              data.map.features.length === 0
                ? pv.center
                : [...data.map.features[0].geometry.coordinates[0][0]],
          };
        });
        if (filterData.changeScheme) {
          setColorScheme(
            data.map.devices.reduce((acc, d, ind) => {
              acc[d] = schemeColors[ind];
              return acc;
            }, {})
          );
        }
        setInsights({
          average: data.insights.averageDistance,
          total: data.insights.totalDistance,
        });
      }, 100);
    } catch (error) {
      setError({ status: true, message: error });
    }
  }, [filterData, fileName, setError]);

  useEffect(() => {
    getData();
  }, [getData]);

  const filterChangeHandler = (newFilter) => {
    setFilterData((pv) => ({ ...pv, ...newFilter, changeScheme: false }));
  };

  const get = useCallback((data, name) => {
    const csvdata = csvmaker(data);
    download(csvdata, name);
  }, []);

  return (
    <>
      <Card className={styles.filter}>
        <Filter
          setError={setError}
          fileName={fileName}
          changeFilter={filterChangeHandler}
        />
      </Card>
      <Insights
        insights={insights}
        get={get.bind(null, distanceAnalysis.data, "Table")}
      />
      <div className={styles["referral-container"]}>
        <Map
          radius={0}
          referralType={filterData.referralType}
          center={[...mapData.center]}
          mapData={mapData.geoJson}
          colorScheme={colorScheme}
        />
        <Bar
          get={get.bind(null, barData, "ProvinceTypeDistance(BarChart)")}
          id={"ProvinceTypeDistance(BarChart)"}
          data={barData.sort((a, b) => a.Distance - b.Distance)}
          heading={"Distance by Province"}
          colorScheme={colorScheme}
          isHorizontal={true}
        />
      </div>
    </>
  );
};

export default React.memo(HistoricalRefferal);
