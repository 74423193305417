import React, { useCallback, useState, useEffect } from "react";
import Button from "../../UI/Button";
import styles from "./Filter.module.css";

import { useAuth0 } from '@auth0/auth0-react';

const Filter = (props) => {
  const [filter, setFilter] = useState({
    Province: [],
    District: [],
    "Test": [],
  });

  const [formData, setFormData] = useState({
    origin_province: "",
    origin_district: "",
    test: "",
  });

  const { fileName } = props;

  const { getAccessTokenSilently } = useAuth0();

  const getFilters = useCallback(async () => {
    const token = await getAccessTokenSilently();

    const url = `${process.env.REACT_APP_API_BASE_URL}/v2/demandCapacityFilters/${fileName}?AdminArea=${formData.origin_district}&Province=${formData.origin_province}`;
    const authHeaders = {
      Authorization: `Bearer ${token}`
    }

    const response = await fetch(url, {headers: authHeaders});
    const data = await response.json();
    if (!response.ok) throw data.errors;
    setFilter({ ...data });
  }, [fileName, formData.origin_district, formData.origin_province]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  const testChangeHandler = (e) => {
    const updatedValue = e.target.value;
    setFormData((pv) => {
      return {
        ...pv,
        test: updatedValue,
      };
    });
  };
  const provinceChangeHandler = (e) => {
    const updatedValue = e.target.value;
    setFormData((pv) => {
      return {
        ...pv,
        origin_province: updatedValue,
        test: "",
      };
    });
  };
  const districtChangeHandler = (e) => {
    const updatedValue = e.target.value;
    setFormData((pv) => {
      return {
        ...pv,
        origin_district: updatedValue,
      };
    });
    setFormData((pv) => {
      return {
        ...pv,
        origin_province: "",
        test: "",
      };
    });
  };

  return (
    <div className={styles["filter-container"]}>
      <div className={styles.filter}>
        <label htmlFor="Origin_District">Province </label>
        <select
          value={formData.origin_district}
          onChange={districtChangeHandler}
          name="Origin_District"
          id="Origin_District"
        >
          <option value="">All</option>
          {filter.District.map((filter, ind) => (
            <option key={ind} value={filter}>
              {filter}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.filter}>
        <label htmlFor="Origin_Province">District </label>
        <select
          value={formData.origin_province}
          onChange={provinceChangeHandler}
          name="Origin_Province"
          id="Origin_Province"
        >
          <option value="">All</option>
          {filter.Province.map((filter, ind) => (
            <option key={ind} value={filter}>
              {filter}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.filter}>
        <label htmlFor="Test">Product</label>
        <select
          value={formData.test}
          onChange={testChangeHandler}
          name="Test"
          id="Test"
        >
          <option value="">All</option>
          {filter["Test"].map((filter, ind) => (
            <option key={ind} value={filter}>
              {filter}
            </option>
          ))}
        </select>
      </div>

      <Button
        onClick={props.changeFilter.bind(null, formData)}
        className={styles.btn}
      >
        Apply
      </Button>
    </div>
  );
};

export default React.memo(Filter);
