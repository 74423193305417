import { useCallback, useState } from "react";

const useOption = (initValue) => {
  const [selected, setSelected] = useState(initValue);

  const optionChangeHandler = useCallback((ind) => {
    setSelected(ind);
  }, []);

  return {
    selected,
    optionChangeHandler,
  };
};

export default useOption;
