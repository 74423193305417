import React from "react";
import { MapContainer, TileLayer, Tooltip, CircleMarker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import styles from "./Map.module.css";
import MarkerClusterGroup from "react-leaflet-cluster";

const Map = (props) => {
  return (
    <div className={styles["map"]}>
      <MapContainer
        className={`${styles.mappy}`}
        center={props.center}
        zoom={5}
        scrollWheelZoom={false}
      >
        <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <MarkerClusterGroup
          maxClusterRadius={100}
          spiderfyOnMaxZoom={true}
          polygonOptions={{
            fillColor: "#ffffff",
            color: "#f00800",
            weight: 5,
            opacity: 1,
            fillOpacity: 0.8,
          }}
          singleMarkerMode
          showCoverageOnHover={true}
        >
          {props.mapData.map((location, ind) => {
            const width =
              ((!location.Capacity_Utilization
                ? 0
                : location.Capacity_Utilization) *
                62.83) /
              100;

            if (location.Lab === undefined || location.Lab === "") {
              props.setError({
                message: [`Lab name for a row in either null or empty string`],
              });
            }

            if (
              location.Latitude === undefined ||
              location.Longitude === undefined
            ) {
              props.setError(
                `latlng values for ${location.Lab} and device type ${location.DeviceType} are null`
              );
            }
            return (
              <CircleMarker
                key={ind}
                center={[location.Latitude, location.Longitude]}
                pathOptions={{
                  fillColor: "red",
                  fillOpacity: 1,
                  stroke: false,
                }}
                radius={20}
              >
                <CircleMarker
                  center={[location.Latitude, location.Longitude]}
                  pathOptions={{
                    color: "blue",
                    weight: 20,
                    dashArray: `${width} 62.83`,
                    fillColor: "transparent",
                    lineCap: "butt",
                    fill: true,
                    fillOpacity: 1,
                  }}
                  radius={10}
                >
                  <Tooltip>
                    <span>{"Lab: " + location.Lab}</span>
                    <br />
                    <span>{"Device type: " + location.DeviceType}</span>
                    <br />
                    <span>
                      {"Utilization: " +
                        (!location.Capacity_Utilization
                          ? "0%"
                          : location.Capacity_Utilization + "%")}
                    </span>
                  </Tooltip>
                </CircleMarker>
              </CircleMarker>
            );
          })}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  );
};

export default Map;
