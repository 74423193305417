import React, { useState, useCallback, useEffect } from "react";
import Card from "../../UI/Card";
import styles from "./Filter.module.css";
import Button from "../../UI/Button";

import { useAuth0 } from '@auth0/auth0-react';

const Filter = React.memo((props) => {
  const [filterData, setFilterData] = useState({
    fromFilter: true,
    province: props.province,
    deviceType: props.deviceType,
  });
  const [filterValues, setFilterValues] = useState({
    provinces: [],
    DeviceTypes: [],
  });
  const { setError, fileName } = props;

  const { getAccessTokenSilently } = useAuth0();

  const getData = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();

      const url = `${process.env.REACT_APP_API_BASE_URL}/v1/filterNames/${fileName}?province=${filterData.province}`;
      const authHeaders = {
        Authorization: `Bearer ${token}`
      }

      const response = await fetch(url, {headers: authHeaders});
      const data = await response.json();
      if (!response.ok) throw new Error(data.message);
      setFilterValues({
        provinces: data.Province.map((province) => province.Province),
        DeviceTypes: data.DeviceType.map((device) => device.DeviceType),
      });
    } catch (error) {
      setError({ status: true, message: error.message });
    }
  }, [fileName, setError, filterData.province]);

  useEffect(() => {
    getData();
  }, [getData]);

  const deviceChangeHandler = (e) => {
    const updatedDevice = e.target.value;
    setFilterData((pv) => {
      return {
        ...pv,
        deviceType: updatedDevice,
      };
    });
  };
  const provinceChangeHandler = (e) => {
    const updatedProvince = e.target.value;
    setFilterData((pv) => {
      return {
        ...pv,
        province: updatedProvince,
      };
    });
  };

  return (
    <Card className={styles.filter}>
      <h1 style={{ margin: "0 auto 0 0" }}>Filter</h1>
      <div>
        <label htmlFor="Province">Province: </label>
        <select
          value={filterData.province}
          onChange={provinceChangeHandler}
          name="province"
          id="Province"
        >
          <option value="">All</option>
          {filterValues.provinces.map((filter, ind) => (
            <option key={ind} value={filter}>
              {filter}
            </option>
          ))}
        </select>
      </div>
      <div style={{ marginLeft: "25px" }}>
        <label htmlFor="devivceType">Equipment type: </label>
        <select
          value={filterData.deviceType}
          onChange={deviceChangeHandler}
          name="deviceType"
          id="devivceType"
        >
          <option value="">All</option>
          {filterValues.DeviceTypes.map((filter, ind) => (
            <option key={ind} value={filter}>
              {filter}
            </option>
          ))}
        </select>
      </div>
      <Button
        onClick={props.changeFilter.bind(null, filterData)}
        className={styles.btn}
      >
        Apply
      </Button>
    </Card>
  );
});

export default Filter;
