import React from "react";
import Button from "../UI/Button";
import styles from "./Footer.module.css";

const Footer = (props) => {
  return (
    <footer className={styles.footer}>
      <img className={styles["footer-img"]} src="./ong.png" alt="myimg"></img>
      <Button className={styles["footer-btn"]} onClick={props.logIn}>
        <h2>Log in</h2>
      </Button>
    </footer>
  );
};

export default Footer;
