import React, { useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import Button from "../UI/Button";
import Options from "./Options/Options";
import Footer from "./Footer";
import Faq from "./FAQ/Faq";

const LogIn = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const logInUser = useCallback(() => {
    loginWithRedirect({
      appState: {
        targetUrl: window.location.pathname + "/dashboard",
      },
    });
  }, [loginWithRedirect]);

  if (!isAuthenticated) {
    logInUser();
    return;
  }
  return !isAuthenticated ? (
    <div>
      <div>
        <div className="lm-menu">
          <div className="lm-menu__left">
            <div className="lm-menu__left__inner">
              <img className="imgfor" src="./ong.png" alt="myimg"></img>
            </div>
          </div>
          <div className="lm-menu__right">
            <div className="lm-menu__right__inner">
              <Button onClick={logInUser}>
                <h2>Log In</h2>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="lm-hero">
          <div className="lm-hero__left">
            <h1 className="lm-hero__header">
              Develop analytics applications using our web application.
            </h1>
            <img
              src="./map-scale.png"
              alt="Scale Your Program"
              className="lm-hero__image"
            ></img>
          </div>
        </div>
      </div>
      <div>
        <div className="lm-why">
          <p className="lm-why__header">Why OptmlAnalytics?</p>
          <p className="lm-why__subheader">
            OptmlAnalytics converts business data and the requirements into
            customized, visually informative and accurate dashboards containing
            charts, graphs and maps that can be updated in real-time.
          </p>
        </div>
      </div>
      <Options />
      <Faq />
      <Footer logIn={logInUser} />
    </div>
  ) : (
    <Navigate to={"/dashboard"} />
  );
};

export default LogIn;
