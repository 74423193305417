import React from "react";
import Card from "../../UI/Card";
import styles from "./Insights.module.css";

const Insights = (props) => {
  return (
    <div className={styles.insights}>
      <Card className={styles["insight-card"]}>
        <div className={styles.middle}>
          <div className={styles.left}>
            <h1>{props.insights.average} Km</h1>
            <span className={styles.tagline}>Average distance</span>
          </div>
          <span
            className="material-icons-sharp"
            style={{ fontSize: "80px", color: "#12a6ad" }}
          >
            connect_without_contact
          </span>
        </div>
      </Card>
      <Card className={styles["insight-card"]}>
        <div className={styles.middle}>
          <div className={styles.left}>
            <h1>{props.insights.total} km</h1>
            <span className={styles.tagline}>Total distance</span>
          </div>

          <span
            className="material-icons-sharp"
            style={{
              fontSize: "80px",
              color: "#12a6ad",
            }}
          >
            social_distance
          </span>
        </div>
      </Card>
      <Card
        onClick={props.get}
        className={`${styles.downloadCard} ${styles["insight-card"]}`}
      >
        <div className={styles.middle}>
          <div className={styles.left}>
            <h1>Download</h1>
            <span className={styles.tagline}>Table</span>
          </div>

          <span
            className="material-icons-sharp"
            style={{
              fontSize: "80px",
              color: "#12a6ad",
            }}
          >
            download
          </span>
        </div>
      </Card>
    </div>
  );
};

export default Insights;
