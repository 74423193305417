import styles from "./Future.module.css";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import React, { useState } from "react";
import HistoricalTesting from "../Dashboard/HistoricalTesting";
import HistoricalReferral from "../Historical Referral/HistoricalReferral";
import Card from "../UI/Card";

const Future = (props) => {
  const [selected, setSelected] = useState("FT");

  const handleChange = (e, newAlignement) => {
    if (newAlignement !== null) setSelected(newAlignement);
  };

  return (
    <>
      <div className={`${styles.toggle}`}>
        <ToggleButtonGroup
          color="primary"
          value={selected}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton value="FT">Future State Capacity</ToggleButton>
          <ToggleButton value="HT"> Future State Transportation Analysis</ToggleButton>
        </ToggleButtonGroup>
      </div>
      {selected === "FT" ? (
        <HistoricalTesting {...props} />
      ) : (
        <HistoricalReferral {...props} />
      )}
    </>
  );
};

export default React.memo(Future);
