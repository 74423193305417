import React, { useState, useEffect, useCallback } from "react";
import Dashboard from "./Components/Dashboard";
import DashboardV2 from "./v2/Components/Dashboard";
import { useAuth0 } from "@auth0/auth0-react";

const AppSeparator = () => {
  const [user, setUser] = useState(null); // If sidebar is collapsed or not
  const { getAccessTokenSilently, logout } = useAuth0();

  const getUser = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();

      const url = `${process.env.REACT_APP_API_BASE_URL}/user`;
      const authHeaders = {
        Authorization: `Bearer ${token}`,
      };

      const response = await fetch(url, { headers: authHeaders });
      const data = await response.json();
      if (!response.ok) {
        throw data.errors;
      }

      if (data.app_version == null || (data.app_version != null && data.app_version != "v1" && data.app_version != "v2")) {
        alert("You are not mapped to a correct version. Please contact admin.")
        logout({
          returnTo: "https://www.optmlanalytics.com/",
        })
      } 


      setUser({ ...data });
    } catch (error) {
      console.error("error: user api failed", error);
      logout({
        returnTo: "https://www.optmlanalytics.com/",
      })
      //   setError({ status: true, message: error });
    }
  }, []);

  useEffect(() => {
    getUser();
    // setUser({app_version: "v2"})
  }, []);

  return (
    <>
      {user &&
        user.app_version &&
        (user.app_version === "v1" ? (
          <Dashboard />
        ) : user.app_version === "v2" ? (
          <DashboardV2 />
        ) : (
          <></>
        ))}
    </>
  );
};

export default AppSeparator;
