import React from "react";
import styles from "./Question.module.css";

const Question = (props) => {
  return (
    <li
      className={`${styles["question-container"]} ${
        props.selected ? styles.selected : ""
      }`}
      onClick={
        props.selected
          ? props.optionChange.bind(null, -1)
          : props.optionChange.bind(null, props.ind)
      }
    >
      <div className={styles.question}>{props.question}</div>
      {props.selected && <div className={styles.answer}>{props.answer}</div>}
    </li>
  );
};

export default Question;
