import { configureStore } from "@reduxjs/toolkit";
import errorReducer from "./errorSlice";
import ScenarioReducer from "./ScenarioSlice";

export default configureStore({
  reducer: {
    error: errorReducer,
    scenarios: ScenarioReducer,
  },
});
