import styles from "./Demand.module.css";
import Card from "../UI/Card";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Map from "./Charts/Map/Map";
import React, { useCallback, useEffect, useState } from "react";
import Filter from "./Filter/Filter";
import Insights from "./Insights/Insights";

import { useAuth0 } from "@auth0/auth0-react";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const schemeColors = [
  "#9E0142",
  "#66C2A5",
  "#3288BD",
  "#F46D43",
  "#CAC111",
  "#5E4FA2",
  "#FDAE61",
  "#CAF510",
  "#FEE08B",
  "#D53E4F",
  "#FFFF30",
];

const Demand = (props) => {
  const { fileName, setError } = props;
  const [colorScheme, setColorScheme] = useState({});

  const [mapData, setMapData] = useState({
    geoJson: {
      type: "features",
      features: [],
      devices: [],
    },
    center: [0, 0],
  });

  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [insights2, setInsights2] = useState({ columns: [], rows: [] });
  const [filters, setFilters] = useState({
    origin_province: "",
    origin_district: "",
    test: "",
    changeScheme: true,
  });
  const [insights, setInsights] = useState([]);

  const { getAccessTokenSilently } = useAuth0();

  const getData = useCallback(async () => {
    try {
      if (fileName === "") {
        const message = ["No file selected"];
        throw message;
      }

      const token = await getAccessTokenSilently();

      const url = `${process.env.REACT_APP_API_BASE_URL}/v2/demandCapacity/${fileName}?province=${filters.origin_province}&district=${filters.origin_district}&test=${filters.test}`;
      const authHeaders = {
        Authorization: `Bearer ${token}`,
      };

      const response = await fetch(url, { headers: authHeaders });
      const data = await response.json();
      if (!response.ok) {
        throw data.errors;
      }
      setMapData((pv) => {
        return {
          geoJson: data.map,
          center:
            data.map.features.length === 0
              ? pv.center
              : [...data.map.features[0].geometry.coordinates],
        };
      });
      setTableData({ columns: data.table.columns, rows: data.table.rows });
      setInsights2({ columns: data.table.columns, rows: data.table.rows });
      setInsights(data.insights);
      if (filters.changeScheme) {
        setColorScheme(
          data.distinctTests.reduce((acc, d, ind) => {
            acc[d] = schemeColors[ind];
            return acc;
          }, {})
        );
      }
    } catch (error) {
      setError({ status: true, message: error });
    }
  }, [fileName, filters, setError]);

  useEffect(() => {
    getData();
  }, [getData]);

  const filterChangeHandler = useCallback((newFilters) => {
    setFilters(newFilters);
  }, []);

  return (
    <>
      <Insights insights={insights} />
      <div className={styles["map-filter"]}>
        <Map
          center={[...mapData.center]}
          mapData={mapData.geoJson}
          colorScheme={colorScheme}
        />
        <Card className={styles.filter}>
          <Filter fileName={fileName} changeFilter={filterChangeHandler} />
        </Card>
      </div>
      <Card className={styles["table-container"]}>
        <div className={styles["table-heading"]}>
          <h1>Demand Capacity Comparison by Province & Product</h1>
        </div>
        <Box sx={{ marginTop: "10px", height: "90%", width: "100%" }}>
          <DataGrid
            components={{ Toolbar: CustomToolbar }}
            rows={tableData.rows}
            columns={tableData.columns.filter((c) => c.field !== "District")}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            disableRowSelectionOnClick
          />
        </Box>
      </Card>
      <Card className={styles["table-container"]}>
        <div className={styles["table-heading"]}>
          <h1>Demand Capacity Comparison by Province, District & Product</h1>
        </div>
        <Box sx={{ marginTop: "10px", height: "90%", width: "100%" }}>
          <DataGrid
            components={{ Toolbar: CustomToolbar }}
            rows={tableData.rows}
            columns={tableData.columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            disableRowSelectionOnClick
          />
        </Box>
      </Card>
    </>
  );
};

export default React.memo(Demand);
