import React from "react";
import useOption from "../../../hooks/use-option";
import styles from "./Faq.module.css";
import Question from "./Question";

const questions = [
  "What is Liftmap?",
  "Is Liftmap an A/B testing tool?",
  "What does Liftmap integrate with?",
  "Is there a limit of how many experiments can be stored?",
  "Do I need an account to use Liftmap?",
];

const answer = [
  "Liftmap is an experimentation project management tool designed to automate and support your testing practice, helping you focus on creating more winning hypotheses.",
  "Liftmap is not an A/B testing tool and does not execute A/B tests. It does, however, integrate with your existing A/B testing tool, allowing you to easily analyze and gain insights from your tests.",
  "There is no limit of how many experiments can be stored in Liftmap. We hope to promote testing, not inhibit it.",
  "Most of Liftmaps features require an account to use. But, some shareable views can be sent to unauthenticated users.",
  "There is no limit of internal users that can use Liftmap. We understand that building a culture of experimentation requires buy-in from many teams.",
];

const Faq = () => {
  const { selected: option, optionChangeHandler } = useOption(0);

  return (
    <div className={styles.faq}>
      <h1 style={{ fontSize: "2rem", marginBottom: "2.5rem" }}>FAQ</h1>
      <ul className={styles.list}>
        {questions.map((q, ind) => (
          <Question
            key={ind}
            ind={ind}
            question={q}
            answer={answer[ind]}
            selected={option === ind}
            optionChange={optionChangeHandler}
          />
        ))}
      </ul>
    </div>
  );
};

export default Faq;
