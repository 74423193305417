import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  Button
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import React, { useState, useEffect, useCallback } from "react";
import Card from "../../UI/Card";
import File from "./File";
import styles from "./Scenario.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  initializeScenario,
  removeScenario,
} from "../../../Store/ScenarioSlice";
import { useAuth0 } from '@auth0/auth0-react';

const Scenario = (props) => {
  const [popup, setPopup] = useState(false);
  const scenarios = useSelector((state) => state.scenarios);
  const dispatch = useDispatch();
  const [file, setFile] = useState({}); // Sets the current file in use

  const { getAccessTokenSilently } = useAuth0();

  const getData = useCallback(async () => {
    const token = await getAccessTokenSilently();

    const url = `${process.env.REACT_APP_API_BASE_URL}/v1/getAllScenario`;
    const headers = {
      Authorization: `Bearer ${token}`
    }
    const response = await fetch(url, {headers: headers});
    const data = await response.json();
    dispatch(initializeScenario({ ...data }));
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const changePopup = () => {
    if (popup) {
      resetFile();
    }
    setPopup((pv) => !pv);
  };

  const handleClick = async (name, is_error) => {
    if (is_error === 1) {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`
      }

      const url = `${process.env.REACT_APP_API_BASE_URL}/v1/getErrors/${name}`;
      fetch(url, {headers: headers})
        .then(async response => {
          // const filename = response.headers.get('Content-Disposition').split('filename=')[1];
          const filename = `errors_${name}.zip`;
          const blob = await response.blob();
          return ({ blob, filename });
        })
        .then(({ blob, filename }) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error => console.error(error));

      return
    }

    props.setFileName(name);
    props.setPage(6);
  };

  const deleteScenario = async (fileName) => {
    props.setFileName("");
    const token = await getAccessTokenSilently();
    const headers = {
      Authorization: `Bearer ${token}`
    }

    const url = `${process.env.REACT_APP_API_BASE_URL}/v1/deleteScenario/${fileName}`;

    const response = await fetch(url, {headers: headers});
    const data = await response.json();
    if (data) {
      dispatch(removeScenario({ name: fileName }));
      props.setPage(0);
    }
  };

  // Function to change file state
  const fileHandler = useCallback((file) => {
    setFile(file);
  }, []);

  // Function to resetFile to default state
  const resetFile = useCallback(() => {
    setFile({});
  }, []);

  const prettyJson = (jsonString) => {
    let prettyJson = '';

    if (jsonString) {
      try {
        const obj = JSON.parse(jsonString);
        prettyJson = JSON.stringify(obj, null, 2);
      } catch (e) {
        console.error('Error parsing JSON string:', e);
        console.log('jsonString', jsonString)
      }
    }

    return prettyJson
  }

  return (
    <div>
      {popup && (
        <File
          resetFile={resetFile}
          setFile={fileHandler}
          closePopup={changePopup}
          file={file}
          {...props}
        />
      )}
      <Card className={styles.heading}>
        <div className={styles["dropdown-container"]}>
          <h1>Available Scenarios</h1>

          <div className={styles["dropdown"]}>
            <Button color="secondary" onClick={changePopup}>
              Create Scenario
            </Button>
          </div>
        </div>
      </Card>
      <TableContainer component={Paper}>
        <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Scenario&nbsp;</b>
              </TableCell>
              <TableCell align="center">
                <b>Uploaded file</b>
              </TableCell>
              <TableCell align="center">
                <b>Created On&nbsp;</b>
              </TableCell>
              <TableCell align="center">
                <b>Remove scenario&nbsp;</b>
              </TableCell>
              <TableCell align="center">
                <b>View results</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scenarios.map((row, id) => (
              <TableRow
                key={id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.FileName}</TableCell>
                <TableCell align="center">{row.createdOn}</TableCell>

                <TableCell align="center">
                  <Button
                    color="error"
                    onClick={deleteScenario.bind(null, row.name)}
                  >
                    Delete
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <Tooltip title={prettyJson(row.remark)}>
                    <Button
                      color={row.is_error == 0 ? "primary" : "warning"}
                      onClick={handleClick.bind(null, row.name, row.is_error)}
                      disabled={row.is_error == 0 && row.is_processed == 0}
                    >
                      {row.is_error == 1 ? 'View errors' : row.is_processed == 1 ? "View results" : "Processing now ..."}
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default React.memo(Scenario);
