import { createSlice } from "@reduxjs/toolkit";
export const errorSlice = createSlice({
  name: "error",
  initialState: {
    status: false,
    message: [""],
  },
  reducers: {
    throwError: (state, action) => {
      if (state.status) {
        return {
          ...state,
          messages: [...state.message, ...action.payload.messages],
        };
      }
      return {
        ...state,
        status: action.payload.status,
        messages: [...action.payload.messages],
      };
    },
    acknowledgeError: (state) => {
      return {
        status: false,
        messages: [""],
      };
    },
  },
});

export const { throwError, acknowledgeError } = errorSlice.actions;
export default errorSlice.reducer;
