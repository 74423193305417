import React from "react";
import styles from "./Bar.module.css";
import Card from "../../../UI/Card";
import { ResponsiveBar } from "@nivo/bar";

const MyResponsiveBar = (props) => {
  return (
    <div id={props.id} className={styles["bar-chart"]}>
      <ResponsiveBar
        data={props.data}
        keys={["Distance"]}
        indexBy={props.indexBy}
        margin={{ top: 10, right: 35, bottom: 60, left: 90 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "set3" }}
        layout={props.isHorizontal ? "horizontal" : "vertical"}
        enableLabel={props.enableLabel}
        borderColor={"#222222"}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: (v) => {
            return v.length > 7 ? (
              <tspan>
                {v.substring(0, 3) + "..."}
                <title>{v + (props.showPercentage ? "%" : "")}</title>
              </tspan>
            ) : (
              v + (props.showPercentage ? "%" : "")
            );
          },
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -30,
          legend: props.legendBottom,
          legendPosition: "middle",
          legendOffset: 50,
        }}
        axisLeft={{
          format: (v) => {
            return v.length > 7 ? (
              <tspan>
                {v.substring(0, 7) + "..."}
                <title>{v + (props.showPercentage ? "%" : "")}</title>
              </tspan>
            ) : (
              v
            );
          },
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: props.legendLeft,
          legendPosition: "middle",
          legendOffset: -60,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={"white"}
        label={(d) => `${d.value + (props.showPercentage ? "%" : "")}`}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-left",
            direction: "column",
            justify: false,
            translateX: 95,
            translateY: 60,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        ariaLabel={props.heading}
        barAriaLabel={function (e) {
          return (
            e.id + ": " + e.formattedValue + " in Province: " + e.indexValue
          );
        }}
        enableGridY={false}
        tooltip={({ id, indexValue, value, color }) => (
          <div
            style={{
              padding: 12,
              color,
              background: "#222222",
            }}
          >
            <strong>
              {id +
                " of " +
                indexValue +
                " : " +
                value +
                (props.showPercentage ? "%" : "")}
            </strong>
          </div>
        )}
      />
    </div>
  );
};

const svgToPng = function (svgText, margin, fill) {
  // convert an svg text to png using the browser
  return new Promise(function (resolve, reject) {
    try {
      // can use the domUrl function from the browser
      var domUrl = window.URL || window.webkitURL || window;
      if (!domUrl) {
        throw new Error("(browser doesnt support this)");
      }

      // figure out the height and width from svg text
      var match = svgText.match(/height=\"(\d+)/m);
      var height = match && match[1] ? parseInt(match[1], 10) : 200;
      var match = svgText.match(/width=\"(\d+)/m);
      var width = match && match[1] ? parseInt(match[1], 10) : 200;
      margin = margin || 0;

      // it needs a namespace
      if (!svgText.match(/xmlns=\"/im)) {
        svgText = svgText.replace(
          "<svg ",
          '<svg xmlns="http://www.w3.org/2000/svg" '
        );
      }

      // create a canvas element to pass through
      var canvas = document.createElement("canvas");
      canvas.innerHTML = "<h1>Pie chart</h1>";
      canvas.width = height + margin * 2;
      canvas.height = width + margin * 2;
      var ctx = canvas.getContext("2d");

      // make a blob from the svg
      var svg = new Blob([svgText], {
        type: "image/svg+xml;charset=utf-8",
      });

      // create a dom object for that image
      var url = domUrl.createObjectURL(svg);

      // create a new image to hold it the converted type
      var img = new Image();

      // when the image is loaded we can get it as base64 url
      img.onload = function () {
        // draw it to the canvas
        ctx.drawImage(this, 0, 0);

        // if it needs some styling, we need a new canvas
        if (fill) {
          var styled = document.createElement("canvas");
          styled.width = canvas.width;
          styled.height = canvas.height;
          var styledCtx = styled.getContext("2d");
          styledCtx.save();
          styledCtx.fillStyle = fill;
          styledCtx.fillRect(0, 0, canvas.width, canvas.height);
          styledCtx.strokeRect(0, 0, canvas.width, canvas.height);
          styledCtx.restore();
          styledCtx.drawImage(canvas, 0, 0);
          canvas = styled;
        }
        // we don't need the original any more
        domUrl.revokeObjectURL(url);
        // now we can resolve the promise, passing the base64 url
        resolve(canvas.toDataURL());
      };

      // load the image
      img.src = url;
    } catch (err) {
      reject("failed to convert svg to png " + err);
    }
  });
};

const Bar = (props) => {
  const downloadPng = async (e) => {
    const svg = document.getElementById(props.id).children[0].children[0]
      .innerHTML;
    const a = await svgToPng(svg, 100, "white");
    var dlLink = document.createElement("a");
    dlLink.download = props.id;
    dlLink.href = a;
    dlLink.dataset.downloadurl = [
      "image/png",
      dlLink.download,
      dlLink.href,
    ].join(":");
    document.body.appendChild(dlLink);
    dlLink.click();
    document.body.removeChild(dlLink);
  };

  return (
    <Card className={styles.bar}>
      <div className={styles["bar-heading"]}>
        <h2>{props.heading}</h2>
        <div>
          <span
            onClick={downloadPng}
            style={{
              cursor: "pointer",
              fontSize: "1.4rem",
              marginRight: "10px",
            }}
            className="material-icons-sharp"
          >
            add_a_photo
          </span>
          <span
            className="material-icons-sharp"
            style={{ cursor: "pointer", fontSize: "1.4rem" }}
            onClick={props.get}
          >
            download
          </span>
        </div>
      </div>
      <MyResponsiveBar {...props} />
    </Card>
  );
};

export default Bar;
