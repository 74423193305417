import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import styles from "./Navbar.module.css";
import Card from "../UI/Card";
import { Avatar } from "@mui/material";

const Navbar = (props) => {
  const { user } = useAuth0();
  return (
    <Card
      className={`${styles.navbar} ${
        props.isCollapsed && styles["navbar-collapsed"]
      }`}
    >
      <div className={styles.avatar}>
        <Avatar src={user.picture}></Avatar>
        <span className={styles.tooltiptext}>
          {user.name}
          <br />
          {user.email}
        </span>
      </div>
    </Card>
  );
};

export default Navbar;
