import { MapContainer } from "react-leaflet";
import styles from "./Map.module.css";
import MapContent from "./MapContent";

const Map = (props) => {
  return (
    <div id="refferalMap" className={`${styles["map"]}`}>
      <MapContainer
        className={`${styles.mappy}`}
        center={[...props.center]}
        zoom={8}
        scrollWheelZoom={false}
      >
        <MapContent {...props} />
      </MapContainer>
    </div>
  );
};

export default Map;
