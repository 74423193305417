import React, { useState } from "react";
import styles from "./Options.module.css";
import Option from "./Option";

const videos = [
  "https://cdn.liftmap.com/marketing/liftmap-comments.mp4",
  "https://cdn.liftmap.com/marketing/liftmap-dashboard.mp4",
  "https://cdn.liftmap.com/marketing/liftmap-backlog.mp4",
  "https://cdn.liftmap.com/marketing/liftmap-workflow.mp4",
  "https://cdn.liftmap.com/marketing/liftmap-roadmap.mp4",
  "https://cdn.liftmap.com/marketing/liftmap-repository.mp4",
];

const options = [
  "Scenario Creation",
  "Data Upload",
  "Data Preview",
  "Data Cleaning",
  "Data Processing",
  "Dashboard Generation",
];

const Options = () => {
  const [selected, setSelected] = useState(3);

  const optionChangeHandler = (ind) => {
    setSelected(ind);
  };

  return (
    <div className={styles["feature-menu"]}>
      <ul className={styles["options-list"]}>
        {options.map((option, ind) => (
          <Option
            key={ind}
            optionChange={optionChangeHandler.bind(null, ind)}
            selected={selected === ind}
          >
            {option}
          </Option>
        ))}
      </ul>
      <div className={styles["video-frame"]}>
        {videos
          .filter((v, ind) => ind === selected)
          .map((v) => {
            return (
              <video key={v} className={styles.video} autoPlay loop muted>
                <source src={v} type="video/mp4" />
              </video>
            );
          })}
      </div>
    </div>
  );
};

export default Options;
