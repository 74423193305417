import React from "react";
import { MapContainer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import styles from "./Map.module.css";
import MarkerClusterGroup from "react-leaflet-cluster";
import MapContent from "./MapContent";

const Map = (props) => {
  return (
    <div className={styles["map"]}>
      <MapContainer
        className={`${styles.mappy}`}
        center={props.center}
        zoom={5}
        scrollWheelZoom={false}
      >
        <MapContent {...props} />
      </MapContainer>
    </div>
  );
};

export default Map;
