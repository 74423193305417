import styles from "./Future.module.css";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import HistoricalTesting from "../Dashboard/HistoricalTesting";
import HistoricalReferral from "../Historical Referral/HistoricalReferral";
import Card from "../UI/Card";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../UI/Loader";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Container } from "@mui/system";


const Future = (props) => {
  const [selected, setSelected] = useState("FT");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e, newAlignement) => {
    if (newAlignement !== null) setSelected(newAlignement);
  };

  const { getAccessTokenSilently } = useAuth0();

  console.log(props);

  const getOptimizationStatus = async () => {
    console.log("get optimization status");
    const fileName = props.fileName;

    const token = await getAccessTokenSilently();

    const url = `${process.env.REACT_APP_API_BASE_URL}/v2/getOptimisationStatus?fileName=${fileName}`;
    const authHeaders = {
      Authorization: `Bearer ${token}`,
    };

    const response = await fetch(url, {
      method: "GET",
      headers: {
        ...authHeaders,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (!response.ok) throw data.errors;

    console.log(data);

    if (data.success && data?.data.optimisation_status === "completed") {
      setShow(true);
      setLoading(false);
    } else if (data.success && data?.data.optimisation_status === "running") {
      setTimeout(() => {
        getOptimizationStatus();
      }, 5000);
    }
  };

  const runOptimization = async () => {
    console.log("run optimization");
    setLoading(true);

    const fileName = props.fileName;

    const token = await getAccessTokenSilently();

    const url = `${process.env.REACT_APP_API_BASE_URL}/v2/runOptimisation?fileName=${fileName}`;
    const authHeaders = {
      Authorization: `Bearer ${token}`,
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        ...authHeaders,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (!response.ok) {
      setLoading(false);
      throw data.errors;
    }

    console.log(data);

    setLoading(false);
    setShow(true);
  };

  useEffect(() => {
    getOptimizationStatus();
  }, []);

  return (
    <>
      {show && !loading && (
        <div className={`${styles.toggle}`}>
          <ToggleButtonGroup
            color="primary"
            value={selected}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value="FT">Future State Capacity</ToggleButton>
            <ToggleButton value="HT">
              Future State Transportation Analysis
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      )}

      {show && !loading &&
        (selected === "FT" ? (
          <HistoricalTesting {...props} optimisation="true" />
        ) : (
          <HistoricalReferral {...props} optimisation="true" />
        ))}

      {!show && !loading && (
        <Container maxWidth="sm">
        <h2> ________________________________________________________</h2>
        <ButtonGroup
          orientation="vertical"
          variant="contained"
          aria-label="outlined button group"
        >
          <h1>
            Distance minimization | Minimize the weighted distance in the
            network
          </h1>

          <Button
            style={{
              backgroundColor: "#21b6ae",
            }}
            onClick={() => {runOptimization()}}
          >
            Run Optimization
          </Button>
        </ButtonGroup>
        
        <h2> ________________________________________________________</h2>
        <ButtonGroup
          orientation="vertical"
          variant="outlined"
          aria-label="outlined button group"
        >
          <h2>
            Transport cost minimization | Minimize the weighted transport cost
            in the network
          </h2>

          <Button
            onClick={() => {
              console.log("Disabled");
            }}
          >
            Run Optimization
          </Button>
        </ButtonGroup>
        

        <h2> ________________________________________________________</h2>
        <ButtonGroup
          orientation="vertical"
          variant="outlined"
          aria-label="outlined button group"
        >
          <h2>
            Variable cost minimization | Minimize the variable production &
            transport cost in the network
          </h2>

          <Button
            onClick={() => {
              console.log("Disabled");
            }}
          >
            Run Optimization
          </Button>
        </ButtonGroup>
        

        <h2> ________________________________________________________</h2>
        <ButtonGroup
          orientation="vertical"
          variant="outlined"
          aria-label="outlined button group"
        >
          <h2>
            Variable + Fixed cost minimization | Minimize the fixed costs &
            variable
          </h2>

          <Button
            onClick={() => {
              console.log("Disabled");
            }}
          >
            Run Optimization
          </Button>
        </ButtonGroup>
        <h2> ________________________________________________________</h2>
      </Container>
      )}

      {loading && <Loader />}
    </>
  );
};

export default React.memo(Future);
