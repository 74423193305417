import { MapContainer } from "react-leaflet";
import styles from "./Map.module.css";
import React, { Suspense } from "react";
const MapContent = React.lazy(() => import("./MapContent"));

const Map = (props) => {
  return (
    <div id="refferalMap" className={`${styles["map"]}`}>
      <MapContainer
        className={`${styles.mappy}`}
        center={[...props.center]}
        zoom={8}
        scrollWheelZoom={false}
      >
        <Suspense fallback={"Please wait"}>
          <MapContent {...props} />
        </Suspense>
      </MapContainer>
    </div>
  );
};

export default Map;
