import L from "leaflet";
import {
  TileLayer,
  Tooltip,
  useMap,
  LayersControl,
  LayerGroup,
  Circle,
  Marker,
  CircleMarker,
} from "react-leaflet";
import { useCallback, useEffect } from "react";
import styles from "./MapContent.module.css";
import React, { useState } from "react";
import Lab from "../../../../assets/icons/triangle.png";
import MarkerClusterGroup from "react-leaflet-cluster";

const legend = L.control({ position: "bottomleft" });
const recenterMap = (map, center) => {
  map.panTo(new L.latLng([...center]));
};

const LabIcon = new L.Icon({
  iconUrl: Lab,
  iconSize: [20, 20],
});

const MapContent = (props) => {
  const map = useMap();
  const { center, colorScheme } = props;
  const [minimize, setMinimize] = useState(true);

  const changeMinimize = useCallback(() => {
    setMinimize((pv) => !pv);
  }, []);

  useEffect(() => {
    legend.onAdd = () => {
      const div = L.DomUtil.create(
        "div",
        `${minimize ? styles.collapsed : `${styles.info} ${styles.legend}`}`
      );
      div.onclick = changeMinimize;
      if (!minimize) {
        const labelsData = [
          {
            label: "Demand",
            color: "lightGreen",
          },
          {
            color: "Yellow",
            label: "Capacity",
          },
        ];

        let labels = [];

        labelsData.forEach((label) => {
          labels.push(
            `<div class=${styles.spacing}><i style="background:` +
              label.color +
              '"></i> ' +
              label.label +
              "</div>"
          );
        });
        div.innerHTML = labels.join("");
      } else {
        div.innerHTML = `<span class="material-icons-sharp">legend_toggle</span>`;
      }
      return div;
    };
    recenterMap(map, center);
    legend.addTo(map);
  }, [center, changeMinimize, colorScheme, map, minimize]);

  return (
    <>
      <LayersControl position="topright">
        <LayersControl.BaseLayer name={`Basic view`}>
          <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer checked name="High contrast - dark">
          <TileLayer url="https://{s}.tile.jawg.io/jawg-dark/{z}/{x}/{y}{r}.png?access-token=zR3ZLKZ094PCqnfe9kuDQzMus1bZXR1CrQMYWqjrpUmv8xrBszAhqOIhCQ1Hj2a5" />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="High contrast - light">
          <TileLayer url="https://{s}.tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token=zR3ZLKZ094PCqnfe9kuDQzMus1bZXR1CrQMYWqjrpUmv8xrBszAhqOIhCQ1Hj2a5" />
        </LayersControl.BaseLayer>

        <LayersControl.Overlay checked name="Demand">
          <LayerGroup>
            {props.mapData.features
              .filter((f) => f.properties.Flag === "Demand")
              .map((feature) => {
                return (
                  <CircleMarker
                    center={feature.geometry.coordinates}
                    radius={
                      feature.properties.labData[0].Quantity < 40
                        ? (feature.properties.labData[0].Quantity + 1) * 200
                        : feature.properties.labData[0].Quantity > 1000
                        ? feature.properties.labData[0].Quantity * 0.2
                        : feature.properties.labData[0].Quantity * 25
                    }
                    pathOptions={{
                      fill: true,
                      fillColor: "lightGreen",
                      stroke: false,
                      fillOpacity: 0.5,
                      radius: 20.8,
                    }}
                  >
                    <Tooltip>
                      {"Health Facility : " + feature.properties.Site}
                      <br />
                      {"Province : " + feature.properties.province}
                      <br />
                      {"District : " + feature.properties.District}
                      <br />
                      {feature.properties.labData.map((d) => {
                        return (
                          <>
                            {"Product : " + d.Test}
                            <br />
                            {"Demand : " + d.Quantity.toFixed(0)}
                          </>
                        );
                      })}
                    </Tooltip>
                  </CircleMarker>
                );
              })}
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name={`Capacity`}>
          <LayerGroup>
            {props.mapData.features
              .filter((f) => f.properties.Flag === "Capacity")
              .map((feature) => {
                return (
                  <Marker
                    position={feature.geometry.coordinates}
                    icon={LabIcon}
                  >
                    <Tooltip>
                      {"Plant : " + feature.properties.Site}
                      <br />
                      {"Province : " + feature.properties.province}
                      <br />
                      {feature.properties.labData.map((d) => {
                        return (
                          <>
                            {"Product : " + d.Test}
                            <br />
                            {"Capacity : " + d.Capacity.toFixed(0)}
                          </>
                        );
                      })}
                    </Tooltip>
                  </Marker>
                );
              })}
          </LayerGroup>
        </LayersControl.Overlay>
      </LayersControl>
    </>
  );
};

export default React.memo(MapContent);
