import { createSlice } from "@reduxjs/toolkit";

export const scenarioSlice = createSlice({
  name: "Scenarios",
  initialState: [],
  reducers: {
    initializeScenario: (state, action) => {
      if (action.payload.data.length === 0) return [];
      return [...action.payload.data];
    },
    addScenario: (state, action) => {
      return [...state, action.payload];
    },
    removeScenario: (state, action) => {
      return state.filter((s) => s.name !== action.payload.name);
    },
  },
});

export const { addScenario, initializeScenario, removeScenario } =
  scenarioSlice.actions;
export default scenarioSlice.reducer;
