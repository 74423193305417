import React, { useEffect } from "react";
import MarkerClusterGroup from "react-leaflet-cluster";
import {
  TileLayer,
  Tooltip,
  Marker,
  CircleMarker,
  useMap,
  LayersControl,
} from "react-leaflet";
import L from "leaflet";
import red from "../../../../assets/icons/red.png";
import yellow from "../../../../assets/icons/yellow.png";
import lightGreen from "../../../../assets/icons/lightGreen.png";
import darkGreen from "../../../../assets/icons/darkGreen.png";
import purple from "../../../../assets/icons/purple.png";
import styles from "./MapContent.module.css";

const redPin = new L.Icon({
  iconUrl: red,
  iconSize: [30, 30],
});
const yellowPin = new L.Icon({
  iconUrl: yellow,
  iconSize: [30, 30],
});
const lightGreenPin = new L.Icon({
  iconUrl: lightGreen,
  iconSize: [30, 30],
});
const darkGreenPin = new L.Icon({
  iconUrl: darkGreen,
  iconSize: [30, 30],
});
const purplePin = new L.Icon({
  iconUrl: purple,
  iconSize: [30, 30],
});

const getColor = (util) => {
  return 0 <= util && util <= 25
    ? "red"
    : 25 < util && util <= 50
    ? "yellow"
    : 50 < util && util <= 75
    ? "lightGreen"
    : 75 < util && util <= 100
    ? "darkGreen"
    : "purple";
};

const getIcon = (util) => {
  return 0 <= util && util <= 25
    ? redPin
    : 25 < util && util <= 50
    ? yellowPin
    : 50 < util && util <= 75
    ? lightGreenPin
    : 75 < util && util <= 100
    ? darkGreenPin
    : purplePin;
};

const legend = L.control({ position: "bottomleft" });
legend.onAdd = () => {
  const div = L.DomUtil.create("div", `${styles.info} ${styles.legend}`);
  const utils = [0, 25, 50, 75, 100, "and more"];
  let labels = [];
  labels.push(`<h3>Capacity Utilization</h3>`);
  for (let i = 0; i < utils.length - 1; i++) {
    let from = utils[i];
    let to = utils[i + 1];
    labels.push(
      `<div class=${styles.spacing}><i style="background:` +
        getColor((from + to) / 2) +
        '"></i> ' +
        from +
        "% - " +
        to +
        `${to === "and more" ? "" : "%"}` +
        "</div>"
    );
  }

  div.innerHTML = labels.join("");
  return div;
};

const createClusterCustomIcon = (cluster) => {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: styles["custom-marker-cluster"],
    iconSize: L.point(33, 33, true),
  });
};

const MapContent = (props) => {
  const map = useMap();

  const { center } = props;

  useEffect(() => {
    // recenterMap(map, center);
    legend.addTo(map);
  }, [center, map]);

  return (
    <>
      <LayersControl>
        <LayersControl.BaseLayer
          checked
          children={<input type={"checkbox"} />}
          name={`Basic view`}
        >
          <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="High contrast - dark">
          <TileLayer url="https://{s}.tile.jawg.io/jawg-dark/{z}/{x}/{y}{r}.png?access-token=zR3ZLKZ094PCqnfe9kuDQzMus1bZXR1CrQMYWqjrpUmv8xrBszAhqOIhCQ1Hj2a5" />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="High contrast - light">
          <TileLayer url="https://{s}.tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token=zR3ZLKZ094PCqnfe9kuDQzMus1bZXR1CrQMYWqjrpUmv8xrBszAhqOIhCQ1Hj2a5" />
        </LayersControl.BaseLayer>
      </LayersControl>
      <MarkerClusterGroup
        iconCreateFunction={createClusterCustomIcon}
        maxClusterRadius={25}
        spiderfyOnMaxZoom={true}
        polygonOptions={{
          weight: 5,
          opacity: 1,
          fillOpacity: 0.1,
        }}
        // showCoverageOnHover={true}
      >
        {props.mapData.map((location, ind) => {
          if (!location.Lab || location.Lab === "") {
            props.setError({
              message: [`Lab name for a row in either null or empty string`],
            });
          }

          if (!location.Latitude || !location.Longitude) {
            props.setError(
              `latlng values for ${location.Lab} and device type ${location.DeviceType} are null`
            );
          }
          return (
            <Marker
              icon={getIcon(location.Capacity_Utilization)}
              position={[location.Latitude, location.Longitude]}
            >
              <Tooltip>
                <span>{"Plant: " + location.Lab}</span>
                <br />
                <span>{"Product: " + location.DeviceType}</span>
                <br />
                <span>
                  {"Utilization: " +
                    (!location.Capacity_Utilization
                      ? "0%"
                      : location.Capacity_Utilization + "%")}
                </span>
              </Tooltip>
              {/* <CircleMarker
            center={[location.Latitude, location.Longitude]}
            pathOptions={{
              color: "blue",
              weight: 10,
              dashArray: `${width} 125.66`,
              fillColor: "yellow",
              lineCap: "butt",
              fill: true,
              fillOpacity: 0.4,
            }}
            radius={20}
          >
          </CircleMarker> */}
            </Marker>
          );
        })}
      </MarkerClusterGroup>
    </>
  );
};

export default MapContent;
