import LogIn from "./Components/Authentication/LogIn";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./Components/Utils/PrivateRoute";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "./Components/UI/Loader";
import store from "./Store/store";
import { Provider } from "react-redux";
import AppSeparator from "./AppSeparator";

function App() {
  const { isLoading } = useAuth0();
  const content = (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route
              element={
                <>
                  <AppSeparator />
                </>
              }
              path="/dashboard"
            />
          </Route>
          <Route path="/" element={<LogIn />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );

  return <>{isLoading ? <Loader /> : content}</>;
}

export default App;
